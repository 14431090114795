<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";
@import "@/scss/font";

.step-button {
    @extend .step-button-font;
    background-color: $button-blue;
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 50px;
    padding-left: 20px;
    padding-right: 10px;
    width: 130px;
    box-sizing: border-box;
    cursor: pointer;

    .text {
        color: white;
        flex: 1;
    }

    .arrow {
        display: block;
        background-image: url("../../assets/images/step-button-arrow-right.png");
        background-repeat: no-repeat;
        width: 12px;
        height: 21px;
    }

    &.left {
        flex-direction: row-reverse;
        padding-left: 10px;
        padding-right: 15px;

        .text {
            text-align: right;
        }

        .arrow {
            background-image: url("../../assets/images/step-button-arrow-left.png");
        }
    }

    .arrow {
        background-size: cover;
    }

    @media (max-width: 767px) {
        border-radius: 5px;
        height: 40px;
        padding-left: 17px;
        padding-right: 12px;
        width: 100px;

        .arrow {
            width: 8px;
            height: 13px;
        }

        &.left {
            flex-direction: row-reverse;
            padding-left: 16px;
            padding-right: 14px;
        }
    }
}
</style>

<template>
    <div
        :class="['step-button', direction]"
        @click.prevent="click()"
        @touchmove.stop=""
    >
        <slot><span class="text" v-html="buttonText"></span></slot>
        <span class="arrow"></span>
    </div>
</template>

<script type="text/babel">
import TranslationMixin from "../mixins/TranslationMixin";
export default {
    name: "step-button",
    props: ["direction"],
    methods: {
        click() {
            this.$emit("click");
        }
    },
    computed: {
        buttonText() {
            let location = "NextButton";
            let defaultTranslation = "Next";

            if (this.direction === "left") {
                location = "PrevButton";
                defaultTranslation = "Previous";
            }

            try {
                return this.t("Modules", location, 0);
            } catch (e) {
                return defaultTranslation;
            }
        }
    },
    mixins: [TranslationMixin]
};
</script>
