<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.customer-testimonial {
    padding: 20px 50px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .testimonial-carousel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        > .arrow {
            width: 36px;
            height: 36px;
            background-color: $carousel-button;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            &.left {
                padding-right: 3px;
                margin-right: 10px;

                .arrow-icon {
                    background-image: url("../../../assets/images/carousel-arrow-left.png");
                    width: 8px;
                    height: 13px;
                    background-size: cover;
                }
            }

            &.right {
                padding-left: 3px;
                margin-left: 10px;
                .arrow-icon {
                    background-image: url("../../../assets/images/carousel-arrow-right.png");
                    width: 8px;
                    height: 13px;
                    background-size: cover;
                }
            }
        }
    }

    h1 {
        margin-bottom: 50px;
    }

    .testimonials {
        max-width: 890px;
        width: 100%;
    }

    .testimonial-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .testimonial-item {
        width: 100%;
    }

    @media (max-width: 767px) {
        padding: 20px 10px;

        h1 {
            margin-bottom: 20px;
        }

        .arrow {
            width: 36.5px;
            height: 36.5px;
            border-width: 1.5px;

            &.left {
                left: -20.5px;
                padding-right: 1.5px;
                margin-right: 5px;

                .arrow-icon {
                    width: 7.5px;
                    height: 13px;
                }
            }

            &.right {
                right: -20.5px;
                padding-left: 1.5px;
                margin-left: 5px;

                .arrow-icon {
                    width: 7.5px;
                    height: 13px;
                }
            }
        }
    }
}
</style>

<template>
    <div class="customer-testimonial">
        <div class="expander"></div>
        <div class="testimonial-carousel">
            <div class="arrow left" @touchmove.stop="" @click="left()">
                <div class="arrow-icon"></div>
            </div>

            <div
                class="testimonial-content"
                @touchstart="touchstart($event)"
                @touchend="touchend($event)"
            >
                <h1>
                    <content-item :config="content.header"></content-item>
                </h1>

                <div class="testimonials">
                    <div
                        v-for="(testimonial, index) in content.testimonials"
                        v-if="index === currentItemIndex"
                    >
                        <content-item
                            class="testimonial-item"
                            :config="testimonial"
                        ></content-item>
                    </div>
                </div>

                <summary-navigation
                    :currentIndex="currentItemIndex"
                    :quantity="content.testimonials.length"
                    @change="updateIndex($event)"
                ></summary-navigation>
            </div>

            <div class="arrow right" @touchmove.stop="" @click="right()">
                <div class="arrow-icon"></div>
            </div>
        </div>
        <div class="expander"></div>
        <arrow-controls :parentSectionIndex="sectionIndex"></arrow-controls>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
import SummaryNavigation from "../../SummaryNavigation.vue";
export default {
    name: "customer-testimonial",
    data() {
        return {
            intervalId: null,
            currentItemIndex: 0,
            currentTestimonialTouchStart: 0,
            currentTestimonialTouchDist: 0
        };
    },
    mounted() {
        let vm = this;
        this.intervalId = setInterval(function() {
            vm.right();
        }, 10000);
        this.updateActiveTestimonial();
    },
    methods: {
        left() {
            if (this.currentItemIndex > 0) {
                this.currentItemIndex--;
            } else {
                this.currentItemIndex = this.content.testimonials.length - 1;
            }
        },
        right() {
            if (this.currentItemIndex < this.content.testimonials.length - 1) {
                this.currentItemIndex++;
            } else {
                this.currentItemIndex = 0;
            }
        },
        updateIndex(index) {
            this.currentItemIndex = index;
            this.clearInterval();
        },
        clearInterval() {
            if (this.intervalId !== null) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        updateActiveTestimonial() {
            let testimonial = this.content.testimonials[this.currentItemIndex]
                .translation;
            this.$store.commit("setCurrentTestimonial", testimonial);
        },
        touchstart(e) {
            let touchobj = e.changedTouches[0]; // reference first touch point (ie: first finger)
            this.currentTestimonialTouchStart = parseInt(touchobj.clientX); // get x position of touch point relative to left edge of browser
            e.preventDefault();
        },
        touchend(e) {
            let touchobj = e.changedTouches[0]; // reference first touch point for this event
            this.currentTestimonialTouchDist =
                parseInt(touchobj.clientX) - this.currentTestimonialTouchStart;
            e.preventDefault();
        }
    },
    computed: {
        hasReachedSection() {
            return (
                this.$store.state.testimonialSectionIndex + 1 <=
                this.$store.state.currentSectionIndex
            );
        }
    },
    watch: {
        hasReachedSection(newVal) {
            if (newVal) {
                // Disable testimonial bar
                this.$store.commit("setShowTestimonialBar", false);
            } else {
                this.$store.commit("setShowTestimonialBar", true);
            }
        },
        currentItemIndex(value) {
            this.updateActiveTestimonial();
        },
        currentTestimonialTouchDist(value) {
            let percent = 5; // percentage of width the distance must be before changing carousel  section index
            if (value > (document.body.clientWidth * percent) / 100) {
                this.left();
            } else if (value < (document.body.clientWidth * -percent) / 100) {
                this.right();
            }
        }
    },
    mixins: [SectionMixin],
    components: {
        SummaryNavigation
    }
};
</script>
