<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.welcome-simple {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 30px 20px;

    h1 {
        margin-bottom: 20px;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
    }

    @media (max-width: 767px) {
    }
}
</style>

<template>
    <div class="welcome-simple">
        <div class="expander"></div>
        <div>
            <h1>
                <content-item :config="content.header"></content-item>
            </h1>

            <p>
                <content-item :config="content.content"></content-item>
            </p>
        </div>
        <div class="expander"></div>
        <div class="scroll-down">
            <arrow-controls :parentSectionIndex="sectionIndex"></arrow-controls>
        </div>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
export default {
    name: "welcome-simple",
    data() {
        return {};
    },
    mounted() {
        this.complete();
    },
    mixins: [SectionMixin]
};
</script>
