<template>
    <div
        class="page-wrapper"
        :style="{ 'background-image': 'url(' + getBackground + ')' }"
    >
        <div class="content-container" ref="content">
            <div class="page-layout-content">
                <h1 class="header text-uppercase">{{ t("header") }}</h1>
                <p class="intro" v-html="t('intro')"></p>

                <transition name="fade" mode="out-in">
                    <div class="response-saved" v-if="state.saved">
                        <p class="text-primary">
                            <span
                                v-if="hasContent('confirmation')"
                                v-html="t('confirmation')"
                            ></span>
                            <strong v-else
                                >Thank you for submitting your comments.</strong
                            >
                        </p>
                        <loading-button
                            type="button"
                            class="inline"
                            @click="goHome"
                            >Return Home</loading-button
                        >
                    </div>
                    <div v-else>
                        <form v-show="questions.length" @submit.prevent="save">
                            <crowdsourcing-question
                                v-for="(question, questionIndex) in questions"
                                :key="question.id"
                                :name="`question-${question.id}`"
                                :content="question.content"
                                :last="questionIndex === question.length - 1"
                                v-model="responses[question.id]"
                                @input="
                                    validateQuestion(question.id, {
                                        onlyIfSubmitted: true
                                    })
                                "
                                :required="true"
                                :invalid="hasError(question.id)"
                            >
                                <template #error>
                                    <span
                                        class="error"
                                        v-show="hasError(question.id)"
                                        aria-describedby="`question-${question.id}`"
                                    >
                                        {{ getError(question.id) }}
                                    </span>
                                </template>
                            </crowdsourcing-question>
                            <div
                                class="response-error"
                                v-if="state.submitError"
                            >
                                <div class="alert alert-error">
                                    There was a problem submitting the form.
                                </div>
                            </div>
                            <loading-button
                                type="submit"
                                v-model="state.saving"
                                >{{ t("button") || "Submit" }}</loading-button
                            >
                        </form>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingButton from "../buttons/LoadingButton";
import CrowdsourcingQuestion from "../modules/templates/CrowdsourcingQuestion";

export default {
    name: "CrowdsourcingPage",
    components: {
        LoadingButton,
        CrowdsourcingQuestion
    },
    data() {
        return {
            errors: {},
            responses: {},
            state: {
                saved: false,
                saving: false,
                submitted: false,
                submitError: false
            }
        };
    },
    computed: {
        ...mapGetters(["user", "getBackground", "crowdsourcingSectionActive"]),
        content() {
            return this.crowdsourcingSectionActive.content || "";
        },
        questions() {
            return this.crowdsourcingSectionActive.modules || [];
        }
    },
    watch: {
        questions: {
            handler() {
                // Clear error state when section has been changed.
                this.state.submitError = false;

                this.$refs.content.scrollTop = 0;
            },
            deep: false
        }
    },
    methods: {
        async save() {
            this.state.submitError = false;
            this.state.saving = true;

            if (!this.validate()) {
                this.state.saving = false;
                this.state.submitted = true;
                return;
            }

            this.state.submitted = true;

            try {
                await this.$http.post("/api/crowdsourcing", {
                    section_id: this.crowdsourcingSectionActive.id,
                    content: this.responses
                });
            } catch (error) {
                this.state.saving = false;
                this.state.submitError = true;
                return;
            }

            this.state.saving = false;
            this.state.saved = true;
            this.$store.commit(
                "setCrowdsourcingSectionComplete",
                this.crowdsourcingSectionActive.id
            );
        },
        getResponse(questionId) {
            return this.responses[questionId] || "";
        },
        hasError(questionId) {
            return typeof this.errors[questionId] !== "undefined";
        },
        getError(questionId) {
            return this.errors[questionId];
        },
        validate() {
            let valid = true;
            this.questions.forEach(question => {
                if (!this.validateQuestion(question.id)) {
                    valid = false;
                }
            });

            return valid;
        },
        validateQuestion(questionId, options = {}) {
            let valid;

            if (options.onlyIfSubmitted === true && !this.state.submitted) {
                return;
            }

            if (this.getResponse(questionId).trim().length < 1) {
                this.errors[questionId] = "Please enter a comment.";
                valid = false;
            } else {
                valid = true;
                delete this.errors[questionId];
            }

            return valid;
        },
        goHome() {
            this.$store.commit("setShowCrowdsourcingPage", false);
        },
        hasContent(name) {
            return (
                this.content !== "" &&
                typeof this.content[name] != "undefined" &&
                this.content[name].translation
            );
        },
        t(name) {
            if (
                this.content !== "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/scss/color";

.page-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    background-color: $background-color;
    background-size: cover;
    background-position: center 0;
    justify-content: center;
    align-items: stretch;
}
.content-container {
    overflow: auto;
    justify-content: flex-start;
}
.page-layout-content {
    width: 80%;
    margin: auto;
}

h1.header {
    font-size: 40px;
    padding: 30px;
    line-height: 50px;
    margin-top: 30px;
}

p {
    margin-bottom: 1rem;

    &.intro {
        margin-bottom: 2rem;
        font-size: 18px;
        text-align: center;
    }
}

.error {
    color: $primary-color;
}

form {
    display: block;
    margin: 0 0 1rem 0;
    border-top: 1px solid #000;
    padding-top: 2rem;
}

.response-saved {
    text-align: center;
    margin: 0 0 1rem 0;
}

.alert {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 5px solid white;
    color: #000;

    &.alert-success {
        background-color: #c6fdc6;
        border-color: #287528;
    }

    &.alert-error {
        background-color: #efaab5;
        border-color: #a02e2e;
    }
}

@media (max-width: 767px) {
    .page-container {
        width: 100%;
    }
    .page-layout-content {
        width: 85%;
    }
    h1.header {
        padding: 0px;
        font-size: 30px;
    }
}
@media (max-width: 1024px) {
    .page-container {
        width: 90%;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
</style>
