<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";

.question-component {
    margin-bottom: 40px;
    max-width: 60%;

    .question-title {
        font-size: 22px;
        font-wieght: bold;
    }
    .question-body {
        font-size: 22px;
        font-wieght: bold;
        margin-bottom: 20px;
    }
    .question-answers {
        margin-bottom: 10px;
        margin-left: 5px;
    }
    .answer {
        border-radius: 20px;
        padding: 5px;
        margin: 5px 0;
    }
    .highlight {
        background-color: #ccaf5f;
        border-radius: 20px;
    }
    .question-after-answer {
        font-size: 14px;
        line-height: 20px;
        padding: 10px 20px;
        margin-bottom: 25px;
    }
    .btn-area {
        display: flex;
        justify-content: center;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 60px;
        cursor: pointer;
        line-height: 30px;
        display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border: 3px solid #000;
        border-radius: 100%;
        background: transparent;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 24px;
        height: 24px;
        background: #ccaf5f;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 100%;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
}

.btn.default-cursor {
    cursor: default !important;
}
</style>

<template>
    <div class="question-component">
        <h1 class="question-title" v-html="questionData.title"></h1>
        <div class="question-body" v-html="questionData.body"></div>

        <!--<answer-component :data="data.answers" v-model="answerModel"></answer-component>-->
        <div class="question-answers">
            <div
                v-for="(answer, index) in questionData.answers"
                :key="'option' + questionIndex + '-' + index"
                class="answer"
                v-bind:class="{
                    highlight: isCorrectIdx == index && showNext == true
                }"
            >
                <input
                    type="radio"
                    :id="'answer-' + answer.id"
                    v-model="answerModel"
                    :value="answer.id"
                    name="answer"
                    @change="selectionChanged"
                />
                <label
                    :for="'answer-' + answer.id"
                    v-html="answer.text"
                ></label>
            </div>
        </div>

        <div
            :class="[
                'question-after-answer',
                { highlight: doHighlightAnswer }
            ]"
            v-html="afterText"
        ></div>

        <div class="btn-area">
            <button class="button" v-if="showNext" @click="nextQuestion()">
                Next >
            </button>
            <button
                class="button"
                v-else-if="answerModel !== ''"
                @click="submitQuestion()"
            >
                Submit
            </button>
            <button class="button default-cursor" v-else>Select answer</button>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: "question-component",
    props: ["questionData", "questionIndex", "quizId", "selectedOptions"],
    data() {
        return {
            submitting: false,
            answerModel: ""
        };
    },
    computed: {
        isCorrectIdx() {
            let items = this.questionData.answers || false;

            if (items === false) {
                return false;
            }
            let keys = Object.keys(items);

            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (items[key].is_correct === true) {
                    return key;
                }
            }
            // couldn't find so return 0
            return false;
        },
        showNext() {
            if (typeof this.questionData.user === "undefined") {
                return false;
            }
            return (
                this.questionData.user.is_complete ||
                this.questionData.user.view_only
            );
        },
        afterText() {
            if (typeof this.questionData.user === "undefined") {
                return "&nbsp;";
            }
            let userAnswer = this.questionData.user.answer;
            if (typeof userAnswer !== "undefined") {
                let idx = this.getIndexById(userAnswer);
                if (idx !== false) {
                    return this.questionData.answers[idx].text_after;
                }
            }
            return "&nbsp;";
        },
        doHighlightAnswer() {
            return this.afterText !== '&nbsp;'
        }
    },
    methods: {
        submitQuestion() {
            let vm = this;
            if (this.submitting === false) {
                this.submitting = true;
                this.$http
                    .post("/api/quiz/grade/question/" + this.questionData.id, {
                        answer: this.answerModel
                    })
                    .then(
                        response => {
                            if (response.data.success == true) {
                                this.$store.commit(
                                    "setQuestionData",
                                    response.data
                                );
                                vm.$forceUpdate();
                                vm.submitting = false;

                                this.$emit("submitted");
                            } else {
                                throw new Error("Error saving content.");
                            }
                        },
                        response => {
                            throw new Error("Error saving content.");
                        }
                    );
            }
        },
        nextQuestion() {
            this.$emit("next-question");
        },
        previousQuestion() {
            this.$emit("previous-question");
        },
        getIndexById(id) {
            let items = this.questionData.answers || false;

            if (items === false) {
                return false;
            }
            let keys = Object.keys(items);

            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (items[key].id == id) {
                    return key;
                }
            }
            // couldn't find so return 0
            return false;
        },
        selectionChanged() {
            let selectedOptions = this.selectedOptions.slice();

            let optionIndex = selectedOptions.findIndex(
                row => row.id === this.questionIndex
            );

            if (optionIndex !== -1) {
                selectedOptions[optionIndex].answer = this.answerModel;
            } else {
                selectedOptions.push({
                    id: this.questionIndex,
                    answer: this.answerModel
                });
            }

            this.$emit("update:selectedOptions", selectedOptions);
        },
        isChecked(answer) {
            let selectedOptions = this.selectedOptions.slice();

            let option = selectedOptions.find(
                row => row.id === this.questionIndex
            );

            if (option) {
                return option.answer === answer.id;
            } else {
                return false;
            }
        },
        checkIfRadioSelected() {
            let selectedOptions = this.selectedOptions.slice();
            let option = selectedOptions.find(
                row => row.id === this.questionIndex
            );
            if (typeof option === "undefined") {
                option = this.questionData.user;
            }

            if (option && option.answer !== "undefined") {
                this.answerModel = option.answer;
                let input = document.querySelector("#answer-" + option.answer);
                if (input && !input.checked) {
                    input.checked = true;
                }
            }
        }
    },
    mounted() {
        if (!this.answerModel && this.questionIndex === 0) {
            let selectedOptions = this.selectedOptions.slice();

            let option = selectedOptions.find(
                row => row.id === this.questionIndex
            );
            if (typeof option === "undefined") {
                option = this.questionData.user;
            }

            if (option && option.answer !== "undefined") {
                this.answerModel = option.answer;
                let input = document.querySelector("#answer-" + option.answer);
                if (input && !input.checked) {
                    input.checked = true;
                }
            }
        }
    }
};
</script>
