<style lang="scss" rel="stylesheet/scss">
#pp-main-container {
    height: 100vh;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>

<template>
    <SlideShow
        :currentIndex="parentSectionIndex"
        :showBottomNav="false"
        :showSideNav="false"
        transition-style="stack"
    >
        <slot />
    </SlideShow>
    <!-- <div id="pp-main-container">
		<slot></slot>
	</div> -->
</template>

<script type="text/babel">
import PageSection from "./mixins/PageSection";
import SlideShow from "../components/sections/SlideShow";

export default {
    name: "pagepiling",
    data() {
        return {
            //        localIndex: this.$store.state.currentSectionIndex
        };
    },
    components: { SlideShow },
    props: {
        parentSectionIndex: {
            type: Number,
            default: 0
        }
    },
    mounted() {
        //this.pp(this.$el);
    },
    // methods: {
    //     moveTo(index) {
    //         $.fn.pagepiling.moveTo(index);
    //     }
    // },
    // computed: {
    //     currentIndex() {
    //         return this.$store.state.currentSectionIndex;
    //     }
    // },
    // watch: {
    //     currentIndex(newValue) {
    //         if(newValue !== this.localIndex) {
    //             this.moveTo(newValue);
    //         }
    //     }
    //
    // },
    mixins: [PageSection]
};
</script>
