<template>
    <div class="body-container">
        <div v-if="resource.video.mp4Source" class="box">
            <div class="link-box">
                <a @click="openVideoModal()" class="icon-inline">
                    <div :class="iconClass" :style="iconStyle"></div>
                </a>
                <a
                    @click="openVideoModal()"
                    class="link-copy"
                    v-html="resource.text.translation"
                    rel="noopener noreferrer"
                ></a>
            </div>
        </div>
        <div v-else class="box">
            <div :class="['link-box', { disabled }]">
                <a
                    class="icon-inline"
                    :href="resource.url.translation"
                    :target="
                        isTrackableAsset(resource.url.translation)
                            ? '_blank'
                            : resource.target.translation
                    "
                    @click="onTrackableLinkClick"
                >
                    <div :class="iconClass" :style="iconStyle"></div>
                </a>
                <a
                    class="link-copy"
                    :href="resource.url.translation"
                    :target="
                        isTrackableAsset(resource.url.translation)
                            ? '_blank'
                            : resource.target.translation
                    "
                    v-html="resource.text.translation"
                    rel="noopener noreferrer"
                    @click="onTrackableLinkClick"
                ></a>
            </div>
        </div>
        <modal v-model="isVideoOpen" @closed="closeVideoModal()">
            <content-video
                v-if="resource.video"
                :config="resource.video"
                v-model="videoIsPlaying"
                @play.once="onTrackableVideoPlay(resource.video.mp4Source)"
            ></content-video>
        </modal>
    </div>
</template>

<script>
import Modal from "../../Modal.vue";
import ContentVideo from "../../content/ContentVideo.vue";
import TrackAssetMixin from "@/components/mixins/TrackAssetMixin";

export default {
    components: {
        ContentVideo,
        Modal
    },
    name: "resource-link",
    mixins: [TrackAssetMixin],
    props: ["resource"],
    data() {
        return {
            isVideoOpen: false,
            videoIsPlaying: false
        };
    },
    computed: {
        disabled() {
            return this.isTrackingAsset;
        },
        iconIsResource() {
            // Icon starts with http or https protocol
            return /^(\/assets\/|http(s?):\/\/)/.test(
                this.resource.icon.translation
            );
        },
        iconClass() {
            let classes = this.resource.video.mp4Source
                ? ["video-icon"]
                : ["icon"];
            if (!this.iconIsResource) {
                classes.push(`icon-${this.resource.icon.translation}`);
            }
            return classes;
        },
        iconStyle() {
            return this.iconIsResource
                ? [
                      {
                          "background-image": `url(${this.resource.icon.translation})`
                      }
                  ]
                : null;
        }
    },
    methods: {
        closeVideoModal() {
            this.videoIsPlaying = false;
            this.isVideoOpen = false;
        },
        openVideoModal() {
            this.isVideoOpen = true;
            this.videoIsPlaying = true;
        },
        iconClick() {
            return this.resource.url.translation;
        }
    }
};
</script>

<style lang="scss" rel="scss/stylesheet">
.body-container {
    .link-box {
        display: flex;
        align-items: center;
        .icon-inline {
            padding: 0 7px;
            cursor: pointer;
        }
        .link-copy {
            padding: 0 7px;
            text-align: left;
            cursor: pointer;
            text-decoration: underline;
        }

        &.disabled {
            pointer-events: none;
        }
    }
}
</style>
