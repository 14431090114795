import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { plugin as AxiosPlugin } from "@/plugins/AxiosPlugin";
import { defineRule } from "vee-validate";
import * as AllRules from "@vee-validate/rules";
import vex from "vex-js";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(AxiosPlugin);

// Make all vee-validate rules available globally
Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});

// Global function to open cookie notice modal from anywhere.
window.openCookieNotice = () => {
    document
        .getElementById("nav-header")
        .dispatchEvent(new Event("openCookieNotice"));
};

if (
    window.userData.user.pw_expired &&
    window.location.pathname != "/user/recover-password"
) {
    window.location = "/user/recover-password";
}

vex.registerPlugin(require("vex-dialog"));
vex.defaultOptions.className = "vex-theme-plain";

app.mount("#app");
