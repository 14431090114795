<template>
    <div
        class="slideshowContainer"
        v-if="slideCount > 0"
        tabindex="0"
        @keyup.arrow-right="incrementSlideIndex"
        @keyup.arrow-left="decrementSlideIndex"
    >
        <div class="slides">
            <transition-group :name="transitionClass" mode="in-out" tag="span">
                <renderProp
                    v-for="(_, i) in slideSlots"
                    class="slide"
                    :key="i"
                    :data="getSlide(i)"
                    :id="`slide-${i}`"
                    v-show="currentSlideIndex === i"
                />
            </transition-group>
        </div>
    </div>
</template>
<script>
import ControlsMixin from "../../components/mixins/ControlsMixin";

/** Component to render a computed DOM node */
let renderProp = {
    name: "renderNode",
    props: ["data"],
    render() {
        return this.data;
    }
};

export default {
    name: "Slideshow",
    components: { renderProp },
    mixins: [ControlsMixin],
    data() {
        return {
            currentSlideIndex: 0,
            transitionClass: ""
        };
    },
    props: {
        currentIndex: {
            type: Number,
            default: 0
        },
        mode: {
            type: String,
            default: "default"
        },
        showBottomNav: {
            type: Boolean,
            default: true
        },
        showSideNav: {
            type: Boolean,
            default: true
        },
        loop: {
            type: Boolean,
            default: false
        },
        transitionStyle: {
            type: String,
            default: "fade"
        },
        transitionInterval: {
            type: Number,
            default: 0
        }
    },
    computed: {
        hasMultipleSlides() {
            return Array.isArray(this.$slots.default()[0].children[0].children);
        },
        slideCount() {
            return this.hasMultipleSlides
                ? this.$slots.default()[0].children[0].children.length
                : this.$slots.default()[0].children.length;
        },
        slideSlots() {
            return this.hasMultipleSlides
                ? this.$slots.default()[0].children[0].children
                : this.$slots.default()[0].children;
        },
        showBackButton() {
            return !this.loop
                ? this.showSideNav && this.currentSlideIndex !== 0
                : this.showSideNav && this.loop;
        },
        showForwardButton() {
            return !this.loop
                ? this.showSideNav &&
                      this.slideCount !== this.currentSlideIndex + 1
                : this.showSideNav && this.loop;
        }
    },
    watch: {
        currentIndex(i) {
            this.changeSlide(i);
        }
    },
    methods: {
        getSlide(index) {
            return this.hasMultipleSlides
                ? this.$slots.default()[0].children[0].children[index]
                : this.$slots.default()[0].children[index];
        },
        incrementSlideIndex() {
            if (this.loop) {
                this.slideCount === this.currentSlideIndex + 1
                    ? this.changeSlide(0)
                    : this.changeSlide(this.currentSlideIndex + 1);
            } else {
                this.slideCount === this.currentSlideIndex + 1
                    ? ""
                    : this.changeSlide(this.currentSlideIndex + 1);
            }
        },
        decrementSlideIndex() {
            if (this.loop) {
                this.currentSlideIndex === 0
                    ? this.changeSlide(this.slideCount - 1)
                    : this.changeSlide(this.currentSlideIndex - 1);
            } else {
                this.currentSlideIndex === 0
                    ? ""
                    : this.changeSlide(this.currentSlideIndex - 1);
            }
        },
        changeSlide(targetSlideIndex) {
            if (
                targetSlideIndex !== this.currentSlideIndex &&
                this.transitionStyle === "stack"
            ) {
                if (targetSlideIndex > this.currentSlideIndex) {
                    this.transitionClass = "stack-out";
                } else {
                    this.transitionClass = "stack-in";
                }
                this.$nextTick(() => {
                    this.currentSlideIndex = targetSlideIndex;
                });
            }

            if (
                targetSlideIndex !== this.currentSlideIndex &&
                this.transitionStyle === "fade"
            ) {
                this.transitionClass = "fade";
                this.currentSlideIndex = targetSlideIndex;
            }
        },
        keyboardNav(e) {
            console.log(e);
        }
    }
};
</script>

<style scoped lang="scss">
.slideshowContainer {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
}

.slides {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: auto;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
}

/* Animations for slide stacking in */

.stack-in-enter-active {
    transition: all 0.5s linear;
    z-index: 3;
}

.stack-in-enter-from {
    transform: translateX(-100%);
}

.stack-in-enter-to {
    transform: translateX(0%);
}

.stack-in-leave-active {
    transition: all 0.5s;
    z-index: 0;
}

/* Animations for slide stacking out */

.stack-out-leave-active {
    transition: all 0.5s linear;
    z-index: 2;
}

.stack-out-leave-from {
    transform: translateX(0%);
}

.stack-out-leave-to {
    transform: translateX(-100%);
}

.stack-out-enter-active {
    z-index: 1;
}

/* Animations for fade */

.fade-leave-active {
    transition: all 0.5s linear;
    z-index: 2;
}

.fade-leave-from {
    opacity: 100%;
}

.fade-leave-to {
    opacity: 0%;
}

/*  */

.slideNavContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    pointer-events: none;

    .sideButtonContainer {
        width: 82px;
        height: 82px;
        pointer-events: auto;
        margin: 30px;

        .sideButton {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .sideButton.left {
            background-size: cover;
            background-image: url("../../assets/images/arrow-left.png");
        }

        .sideButton.right {
            background-size: cover;
            background-image: url("../../assets/images/arrow-right.png");
        }
    }

    .navButtonContainer {
        display: flex;
        pointer-events: auto;
        align-self: flex-end;
        margin: 20px;

        .navButton {
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            cursor: pointer;
            opacity: 0.5;
            margin: 4px;
            border: 1px solid #888;
            border-radius: 15px;
            box-sizing: border-box;
        }

        .navButton.active {
            opacity: 1;
            cursor: default;
        }
    }
}
</style>
