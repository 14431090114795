<style lang="scss" rel="stylesheet/scss">
#partner-logo-header {
    height: 36px;
    width: 90px;
    background: no-repeat center;
    flex: 1 1 auto;

    @media (min-width: 767px) {
        display: none;
    }
}
</style>

<template>
    <div
        id="partner-logo-header"
        v-if="showLogo"
        :style="{
            backgroundImage:
                'url(' + setFooterLogoImage.footerLogoImageURL + ')'
        }"
    ></div>
    <div class="expander" v-else></div>
</template>

<script type="text/babel">
import FooterLogoImage from "./mixins/FooterLogoImage";
export default {
    name: "partner-logo-header",
    computed: {
        showLogo() {
            return (
                this.setFooterLogoImage.footerLogoImageURL &&
                this.$router.currentRoute.value.path !== "/user/splash"
            );
        }
    },
    mixins: [FooterLogoImage]
};
</script>
