<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.recover-password-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: $text-primary;

    .footnote {
        //font-size: 12px;
        //padding-top: 20px;
        width: 100%;
        margin-bottom: -100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .recover-password-content {
        position: relative;
        width: 100%;
        max-width: 387px;
        margin: 0 auto;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        .blur-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            // filter: blur(10px);
        }

        h1.form-header {
            font-size: 43px;
            font-weight: bold;
            padding: 20px 0;
            color: #000;
            max-height: 83px;
        }

        .form-container {
            width: 100%;
            max-width: 387px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
                max-width: 247px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                padding: 0 0 20px 0;
                font-weight: 300;

                .forgotPasswordLink {
                    font-size: 12px;
                }
            }

            &.form-body > * {
                padding: 18px 69px 40px 69px;
                width: 350px;
                margin: auto;

                .form * {
                    margin: 5px 0;
                }
                input {
                    width: calc(100% - 4px);
                    height: 40px;
                    font-size: 16px;
                    text-align: center;
                    border: none;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
            }

            .recover-password-footer {
                padding: 13px 0 6px 0;
                //margin: 0px;
                font-size: 13px;
                text-align: center;
                margin: -5px 0 0 0;
                color: $text-footer;

                p {
                    width: 100%;
                    font-size: 12px;
                }
            }
        }

        .recover-password-footer {
            padding: 13px 0 6px 0;
            margin: 0px;
            font-size: 13px;
            text-align: center;
        }
        .register-link {
            margin-left: 56px;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: $text-primary;
        }

        @media (max-width: 767px) {
        }
    }
}
</style>

<template>
    <div id="main-page">
        <nav-header
            :displayMenu="1"
            :toggleMenu="menu"
            :pageContent="pageContent"
        ></nav-header>
        <div id="main-container" class="container-fluid">
            <pagepiling>
                <section-wrapper class="recover-password-template">
                    <div class="content-container">
                        <div class="recover-password-wrapper">
                            <div class="recover-password-content">
                                <h1
                                    class="form-container transparent form-header"
                                >
                                    {{ t("title") }}
                                </h1>
                                <div
                                    v-if="!submitSuccess"
                                    class="form-container transparent form-body active"
                                >
                                    <form
                                        action="/user/recover-password"
                                        method="post"
                                        autocomplete="off"
                                    >
                                        <div class="form">
                                            <div v-html="t('subheader')"></div>
                                            <p>
                                                Choose a password that contains
                                                at least 14 characters. Use at
                                                least 3 of the following:
                                                uppercase, lowercase, number,
                                                symbol. Think of a password
                                                that’s easy to remember—like
                                                favorite movie, book, elementary
                                                school or work address.
                                            </p>
                                            <input
                                                :placeholder="t('password')"
                                                name="password"
                                                type="password"
                                                maxlength="255"
                                                v-model="recoverModel.password"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.password"
                                            >
                                                {{ modelErrors.password[0] }}
                                            </p>
                                            <input
                                                :placeholder="
                                                    t('password_confirm')
                                                "
                                                name="password_confirm"
                                                type="password"
                                                maxlength="255"
                                                v-model="
                                                    recoverModel.password_confirm
                                                "
                                            />
                                            <p
                                                class="error"
                                                v-if="
                                                    modelErrors.password_confirm
                                                "
                                            >
                                                {{
                                                    modelErrors
                                                        .password_confirm[0]
                                                }}
                                            </p>
                                            <button
                                                v-html="t('submit')"
                                                v-on:click="nextButton"
                                                class="button"
                                                :disabled="submitting"
                                            ></button>
                                        </div>
                                        <div
                                            class="recover-password-footer"
                                            v-html="t('footer')"
                                        ></div>
                                    </form>
                                </div>
                                <div
                                    v-else
                                    class="form-container transparent form-body"
                                >
                                    <p v-html="t('success')"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-wrapper>
            </pagepiling>
        </div>
    </div>
</template>

<script type="text/babel">
import { mapGetters, mapMutations } from "vuex";
import NavHeader from "../NavHeader.vue";
import IconFooter from "../IconFooter.vue";
import PageSection from "../mixins/PageSection";
import Pagepiling from "../PagePiling.vue";
import SectionWrapper from "../SectionWrapper.vue";

// Import all section template components
// Foreach incoming section, find the correct template component, and put it in a page piling with the corresponding data.

export default {
    name: "recover-password-page",
    created() {
        this.$http.get("/api/content/page").then(
            response => {
                if (response.data.success === true) {
                    PageSection.methods.setData(response);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    computed: {
        ...mapGetters(["isGuest", "sections", "pageContent"]),
        submitButton() {
            if (this.$store.state.pageContent != null)
                return this.$store.state.pageContent.RecoverPassword.content
                    .submit.translation;
            return "";
        },
        submitTo() {
            if (!this.isGuest) {
                return "/user/reset-password";
            }

            const { nonce, user } = this.$route.params;
            return `/user/reset-password/${nonce}/${user}`;
        },
        content() {
            if (this.pageContent != null)
                return this.pageContent.RecoverPassword.content;
            return "";
        }
    },
    data() {
        return {
            pageName: "Recover Password",
            recoverModel: {},
            modelErrors: {},
            submitSuccess: false,
            submitting: false,
            menu: {
                intro: false,
                epix: false,
                profile: false,
                resource: false,
                contact: true,
                privacy: true,
                terms: false,
                logout: false
            }
        };
    },
    methods: {
        ...mapMutations(["setUserPasswordUpdated"]),
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        },
        nextButton(e) {
            e.stopPropagation();
            e.preventDefault();

            this.submitting = true;
            this.modelErrors = {};

            // validate the form in its entirety
            let formData = this.recoverModel;
            this.$http
                .post(this.submitTo, formData)
                .then(
                    response => {
                        const { hasErrors, errors } = response.data;

                        if (hasErrors) {
                            this.modelErrors = errors;
                            return;
                        }

                        this.submitSuccess = true;

                        if (!this.isGuest) {
                            this.setUserPasswordUpdated();
                        }
                    },
                    error => {
                        const { hasErrors, errors } = error.response.data;

                        if (hasErrors) {
                            this.modelErrors = errors;
                            return;
                        }

                        throw new Error("Error validating content.");
                    }
                )
                .then(() => {
                    this.submitting = false;
                });
        }
    },
    components: {
        NavHeader,
        Pagepiling,
        SectionWrapper,
        IconFooter
    }
};
</script>
