import ContentItem from "../content/ContentItem.vue";
import StepButton from "../buttons/StepButton.vue";
import moment from "moment";

export default {
    props: [
        "content",
        "completedAt",
        "hasPrevious",
        "hasNext",
        "template",
        "isActive",
        "module",
        "doPerformReset",
        "lock_section_modules"
    ],
    data() {
        return {
            isCompleting: false
        };
    },
    methods: {
        reset() {
            this.handleReset();
            this.$emit("reset");
        },
        handleReset() {
            // Implement reset functionality here.
        },
        nextSection() {
            let vm = this;
            let completeCallback = function() {
                vm.$emit("next-section");
            };

            if (this.isCompleting) {
                return;
            }

            if (!this.isComplete) {
                this.complete(completeCallback);
            } else {
                completeCallback();
            }
        },
        previousSection() {
            this.$emit("previous-section");
        },
        onLastModule() {
            this.nextSection();
        },
        complete(callback = null) {
            let vm = this;

            // Do nothing if this module is already complete.
            if (this.isComplete) {
                return;
            }

            // Make sure we don't try to complete the module multiple times at once.
            this.isCompleting = true;

            this.$http
                .post("/api/module/complete", { moduleId: this.module.id })
                .then(
                    response => {
                        if (response.data.success === true) {
                            vm.$store.commit("completeModule", {
                                id: vm.module.id,
                                completed_at: response.data.completed_at
                            });
                            vm.$store.commit(
                                "setLastCompleted",
                                vm.module.title
                            );
                            vm.$store.commit(
                                "updateModuleStatuses",
                                response.data.module_statuses
                            );
                            if (callback !== null) {
                                callback();
                            }
                            vm.isCompleting = false;
                        } else {
                            throw new Error("Could not complete module.");
                        }
                    },
                    response => {
                        throw new Error("Error completed module.");
                    }
                );
        },
        checkForLastModule() {
            // If the current module is the last and not completed, then auto complete it.
            // Override onLastModule() when completion isn't desired.
            if (this.isActive && !this.isCompleted && !this.hasNext) {
                this.onLastModule();
            }
        }
    },
    mounted() {
        this.checkForLastModule();
    },
    watch: {
        isActive(newValue) {
            this.checkForLastModule();
        },
        doPerformReset(newValue) {
            if (newValue) {
                this.reset();
            }
        }
    },
    computed: {
        isComplete() {
            return this.completedAt !== null;
        },
        dateComplete() {
            if (this.completedAt === null) return "";
            return moment(this.completedAt, "YYYY-MM-DD HH:mm:ss").format(
                "MM/DD/YYYY"
            );
        }
    },
    components: {
        ContentItem,
        StepButton
    }
};
