<style lang="scss" rel="stylesheet/scss">
.modal {
    display: none;
    padding: 35px;
    /*padding-bottom: 15px;*/
}

.vex {
    z-index: 10000 !important;

    .modal {
        display: block;
    }
}
</style>

<template>
    <div class="modal">
        <div @touchmove.stop="" @click="close()" class="vex-close"></div>
        <slot></slot>
    </div>
</template>

<script type="text/babel">
import vex from "vex-js";

export default {
    name: "modal",
    props: ["modelValue", "theme"],
    emits: ["update:modelValue", "closed"],
    data() {
        return {
            vexInstance: null
        };
    },
    mounted() {
        if (this.modelValue) {
            this.open();
        }
    },
    unmounted() {
        this.close();
    },
    watch: {
        modelValue(value) {
            if (value) {
                this.open();
            } else {
                this.close();
            }
        }
    },
    methods: {
        open() {
            let vm = this;
            this.vexInstance = vex.dialog.alert({
                unsafeMessage: this.$el,
                className: "vex-theme-plain " + this.theme,
                escapeButtonCloses: false,
                overlayClosesOnClick: false,
                buttons: [],
                afterClose: function() {
                    vm.$emit("update:modelValue", false);
                }
            });
        },
        close() {
            if (this.vexInstance !== null) {
                vex.close(this.vexInstance);
            }
            this.$emit("closed");
        }
    }
};
</script>
