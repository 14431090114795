<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.accordion-item {
    cursor: pointer;
    .accordion-before-title {
        width: 18px;
        height: 18px;
        margin-right: 16px;
        position: relative;
        top: 2px;
        background-size: cover;
    }
    .accordion-title {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 38px;
        display: flex;
    }
    .accordion-body {
        font-size: 13px;
    }
}
.accordion-item:not(.active) {
    .accordion-before-title {
        background-image: url("../../assets/images/step-button-plus.png");
    }
    .accordion-body {
        height: 0;
        max-height: 0;
        padding: 0;
        visibility: hidden;
        transition: all 0s step-end;
        transition-property: visibility, height, padding;
    }
}
.accordion-item.active {
    .accordion-before-title {
        background-image: url("../../assets/images/step-button-minus.png");
    }
    .accordion-body {
        height: auto;
        visibility: visible;
        padding: 0 0 15px 72px;
        transition: all 0.5s linear;
        transition-property: max-height, visibility;
        max-height: 3000px;
    }
}
</style>

<template>
    <div class="accordion">
        <div
            v-for="(item, index) in config.items"
            :config="item"
            :index="index"
            class="accordion-item"
            @click="toggleBody(index, $event)"
        >
            <div class="accordion-title">
                <div class="accordion-before-title"></div>
                <span v-html="item.title"></span>
            </div>
            <div class="accordion-body">
                <content-text :config="getBody(item.body)"></content-text>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import ReplaceMixin from "../mixins/ReplaceMixin";
import ContentItem from "./ContentItem.vue";
import ContentText from "./ContentText.vue";
export default {
    name: "content-accordion",
    props: ["config"],
    data() {
        return {
            activeItemIndex: null,
            openOneAtTime: true
        };
    },
    methods: {
        toggleBody(i, e) {
            this.activeItemIndex = i;
            let item = e.currentTarget;
            let showItem = true;
            if (item.classList.contains("active")) {
                showItem = false;
            }
            if (this.openOneAtTime) {
                let accordionItem = e.currentTarget.parentElement.getElementsByClassName(
                    "accordion-item"
                );
                for (let i = 0; i < accordionItem.length; i++) {
                    accordionItem[i].classList.remove("active");
                }
            }
            if (showItem) {
                e.currentTarget.classList.add("active");
            } else {
                e.currentTarget.classList.remove("active");
            }
        },
        getBody(obj) {
            this.replaceData(obj, {});
            return obj;
        }
    },
    watch: {},
    compute: {},
    mixins: [ReplaceMixin],
    components: {
        ContentText,
        ContentItem
    }
};
</script>
