export default {
    pageTranslations: {},

    install(pageTranslations) {
        this.pageTranslations = pageTranslations;
    },
    t(pageName, sectionName, locationName, fieldPosition) {
        // Check if the translation exists
        let keyPath = [
            pageName,
            "sections",
            sectionName,
            locationName,
            fieldPosition,
            "translation"
        ];
        if (this.checkNested(this.pageTranslations, keyPath)) {
            return this.getNested(this.pageTranslations, keyPath);
        }
        return false;
    },
    checkNested(obj, args) {
        for (let i = 0; i < args.length; i++) {
            if (!obj || !obj.hasOwnProperty(args[i])) {
                return false;
            }
            obj = obj[args[i]];
        }
        return true;
    },
    getNested(obj, args) {
        for (let i = 0; i < args.length; i++) {
            if (!obj || !obj.hasOwnProperty(args[i])) {
                throw new Error(
                    "Could not find value in path " + args.join(".")
                );
            }
            obj = obj[args[i]];
        }
        return obj;
    }
};
