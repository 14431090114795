<style lang="scss" rel="stylesheet/scss">
</style>

<template>
    <div id="quiz-component">
        <div class="content-container">
            <quiz-finish
                v-if="summary"
                :questions="totalQuestions"
                :correct="totalCorrectQuestions"
                :message="completeMessage"
                :button="completeButton"
            ></quiz-finish>
            <template v-else>
                <question-component
                    ref="questionModule"
                    v-for="(question, index) in questions"
                    v-show="isActiveIndex(index)"
                    @previous-question="previous()"
                    @next-question="next()"
                    @submitted="answerSubmitted"
                    :question-data="question"
                    :questionIndex="index"
                    :quizId="quizId"
                    v-model:selectedOptions="selectedOptions"
                    :key="question.id"
                ></question-component>

                <quiz-summary
                    :activeQuestionId="currentQuestion.id"
                    @changeQuestion="setActiveIndex($event)"
                    :quizId="quizId"
                ></quiz-summary>
            </template>
        </div>
    </div>
</template>

<script type="text/babel">
import QuestionComponent from "./QuestionComponent.vue";
import QuizSummary from "./QuizSummary.vue";
import QuizFinish from "./QuizFinish.vue";
import eventBus from "@/event-bus";

export default {
    name: "quiz-component",
    props: ["quizId"],
    data() {
        return {
            summary: false,
            selectedOptions: []
        };
    },
    watch: {
        currentQuestionIndex(val) {
            this.$nextTick(() => {
                this.$refs.questionModule[val].checkIfRadioSelected();
            });
        }
    },
    methods: {
        next() {
            eventBus.$emit("section-change");
            this.incrementQuestion();
        },
        previous() {
            if (this.currentQuestionIndex > 0) {
                this.setActiveIndex(this.currentQuestionIndex - 1);
            }
        },
        incrementQuestion() {
            if (
                this.currentQuestionIndex >=
                this.quizData.questions.length - 1
            ) {
                this.summary = true;
                this.$store.commit("completeQuiz", this.quizId);
                return;
            }
            this.setActiveIndex(this.currentQuestionIndex + 1);
        },
        isActiveIndex(index) {
            return index === this.currentQuestionIndex;
        },
        setActiveIndex(index) {
            this.$store.commit("setCurrentQuestionIndex", index);
            //this.currentQuestionIndex = index;
        },
        getIndexById(id) {
            let items = this.quizData.questions || false;

            if (items === false) {
                return 0;
            }
            let keys = Object.keys(items);

            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (items[key].id == id) {
                    return key;
                }
            }
            // couldn't find so return 0
            return 0;
        },
        answerSubmitted() {
            //console.log("answerSubmitted: ", this.selectedOptions);
        }
    },
    computed: {
        completeMessage() {
            return this.quizData.completeMessage;
        },
        completeButton() {
            return this.quizData.completeButton;
        },
        totalQuestions() {
            return this.questions.length;
        },
        totalCorrectQuestions() {
            return this.questions.filter(question => {
                return (
                    question.user.is_correct === "1" ||
                    question.user.is_correct === true
                );
            }).length;
        },
        quizData() {
            return this.$store.state.quizData[this.quizId];
        },
        currentQuestion() {
            let currentIndex = this.$store.state.currentQuestionIndex;
            return this.quizData.questions[currentIndex];
            //return this.questions[this.currentQuestionIndex];
        },
        currentQuestionIndex() {
            //let currentIndex = this.currentQuestionIdx;
            let currentIndex = parseInt(this.$store.state.currentQuestionIndex);
            if (typeof currentIndex !== "number") {
                return 0;
            }
            return currentIndex;
        },
        questions() {
            return this.quizData.questions;
        }
    },
    components: {
        QuizSummary,
        QuestionComponent,
        QuizFinish
    }
};
</script>
