<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

#section-1 {
    display: flex;
    background-color: $primary-color;
    width: 100%;

    .play-scroll-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        width: 100%;
    }

    .expander.photos-1 {
        display: none;
    }

    .section-data {
        overflow: hidden;
        padding-top: 70px;
    }

    .items {
        display: flex;
        width: 100%;
    }

    .arrow-controls {
        margin-top: 0;
    }

    .journey-meter {
        border: 1px solid white;
        padding: 10px 20px;
        text-align: center;
    }

    .photos {
        position: absolute;
        height: 100%;
        width: 45vh;

        > img {
            width: auto;
            display: block;
            position: absolute;
        }
        flex-shrink: 0;
    }

    .info {
        padding-top: 10px;
        padding-bottom: 10px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        /*height: 100%;*/
        box-sizing: border-box;

        h1 {
            margin-bottom: 10px;
            flex-grow: 0;
        }

        p {
            color: $secondary-color;
            font-weight: 300;
            text-align: center;
            max-width: 600px;
            flex-grow: 0;
        }

        .play-button-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;
            margin-bottom: 10px;
            width: 100%;

            > p {
                width: 100%;
            }
        }

        .play-button {
            margin-bottom: 20px;
            margin-top: 20px;
            width: 80px;
            height: 80px;
        }

        .arrow-controls {
            flex-shrink: 0;
        }
    }

    $travel-distance-x: 150%;
    $travel-distance-y: 60%;

    @media (min-width: 1024px) {
        #photos-1 {
            $first-left-position: -16%;
            $first-top-position: -7.2%;

            .first {
                left: $first-left-position - $travel-distance-x;
                top: $first-top-position - $travel-distance-y;
                height: 50.6%;
            }

            .first.active {
                left: $first-left-position;
                top: $first-top-position;
                transition-property: left, top;
                transition: 1s ease-in-out;
            }

            $second-left-position: -2.3%;
            $second-top-position: 26.5%;
            .second {
                height: 47.78%;
                top: $second-top-position;
                left: $second-left-position - ($travel-distance-x);
                z-index: 1;
            }

            .second.active {
                top: $second-top-position;
                left: $second-left-position;
                transition-property: left, top;
                transition: 1s ease-in-out;
            }

            $third-left-position: -9%;
            $third-top-position: 61%;

            .third {
                height: 48.45%;
                top: $third-top-position + $travel-distance-y;
                left: $third-left-position - $travel-distance-x;
            }

            .third.active {
                top: $third-top-position;
                left: $third-left-position;
                transition-property: left, top;
                transition: 1s ease-in-out;
            }
        }

        #photos-2 {
            right: 0;
            top: 0;
            $first-left-position: 13%;
            $first-top-position: -9.6%;

            .first {
                left: $first-left-position + $travel-distance-x;
                top: $first-top-position - $travel-distance-y;
                height: 52.8%;
            }

            .first.active {
                left: $first-left-position;
                top: $first-top-position;
                transition-property: left, top;
                transition: 1s ease-in-out;
            }

            $second-left-position: 23%;
            $second-top-position: 25%;
            .second {
                height: 52.35%;
                top: $second-top-position;
                left: $second-left-position + ($travel-distance-x);
                z-index: 1;
            }

            .second.active {
                top: $second-top-position;
                left: $second-left-position;
                transition-property: left, top;
                transition: 1s ease-in-out;
            }

            $third-left-position: 6.5%;
            $third-top-position: 55%;

            .third {
                height: 55.22%;
                top: $third-top-position + $travel-distance-y;
                left: $third-left-position + $travel-distance-x;
            }

            .third.active {
                top: $third-top-position;
                left: $third-left-position;
                transition-property: left, top;
                transition: 1s ease-in-out;
            }
        }
    }

    @media (max-width: 1320px) {
        .photos {
            height: 75%;
            top: 0;
            bottom: 0;
            margin: auto 0;
        }

        #photos-2 {
            right: -100px;
        }

        .info {
            p {
                max-width: 500px;
            }
        }
    }

    @media (max-width: 1185px) {
        h1 {
            font-size: 60px;
            margin-bottom: 10px;
        }

        .info {
            p {
                max-width: 500px;
            }
        }
    }

    /* Display picture columns as rows, and don't fly them in anymore? */
    @media (max-width: 1023px) {
        .section-data {
            overflow: visible;
            background-color: $primary-color;
        }

        .expander.top {
            flex: 0 0 auto;
        }

        .expander.photos-1 {
            display: block;
        }

        .info {
            h1 {
                font-size: 40px;
            }

            p {
                font-size: 14px;
            }
        }

        .items {
            flex-direction: column;
        }

        .info {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 0;
            padding-top: 10px;
            overflow-y: auto;
        }

        .photos {
            height: 70px;
            display: flex;
            justify-content: center;
            overflow: hidden;
            position: relative;
            width: 100%;

            img {
                height: 100%;
                position: relative;
            }
        }

        .play-button {
            width: 70px;
            height: 70px;
        }

        #photos-2 {
            display: none;
        }
    }

    @media (max-width: 767px) {
        padding-top: 10px;

        .info {
            .play-button {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }

    @media (max-width: 450px) {
        .info {
            /*.scroll-down {*/
            /*transform: scale(.6);*/
            /*margin-bottom: 0;*/
            /*}*/

            .play-button-wrapper {
                margin-bottom: 20px;
            }
        }

        .photos {
            height: 80px;
        }
        .info {
            p {
                font-size: 14px;
            }
            .scroll-down {
            }
            h1 {
                margin-bottom: 10px;
            }

            .play-button {
                margin-top: 10px;
            }

            .play-button-wrapper {
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }
}
</style>

<template>
    <div id="section-1">
        <div class="items">
            <div class="expander photos-1"></div>
            <div id="photos-1" class="photos">
                <content-item
                    class="first"
                    :config="content.left_photo_1"
                ></content-item>
                <content-item
                    class="second"
                    :config="content.left_photo_2"
                ></content-item>
                <content-item
                    class="third"
                    :config="content.left_photo_3"
                ></content-item>
            </div>
            <div class="info">
                <div class="play-scroll-wrapper">
                    <div class="expander top"></div>
                    <div class="play-button-wrapper">
                        <h1>
                            <content-item
                                :config="content.header"
                            ></content-item>
                        </h1>

                        <p>
                            <content-item
                                :config="content.content"
                            ></content-item>
                        </p>

                        <img
                            class="play-button"
                            src="@/assets/images/play-button.png"
                            @touchmove.stop=""
                            @click="openVideoModal"
                        />

                        <div class="journey-meter">
                            <content-item
                                v-if="!isComplete"
                                :config="content.journey_initial"
                            ></content-item>
                            <content-item
                                v-else-if="allModulesComplete"
                                :config="content.journey_complete"
                            ></content-item>
                            <template v-else
                                ><content-item
                                    :config="content.journey_in_progress"
                                ></content-item>
                                {{ lastCompleted }}</template
                            >
                        </div>
                    </div>

                    <div class="expander"></div>

                    <div class="scroll-down">
                        <arrow-controls
                            :parentSectionIndex="sectionIndex"
                        ></arrow-controls>
                    </div>

                    <modal v-model="isVideoOpen" @closed="closeVideoModal()">
                        <content-video
                            :config="content.video"
                            v-model="videoIsPlaying"
                            :onComplete="videoCompleteCallback"
                        ></content-video>
                    </modal>
                </div>
            </div>
            <div id="photos-2" class="photos">
                <content-item
                    class="first"
                    :config="content.right_photo_1"
                ></content-item>
                <content-item
                    class="second"
                    :config="content.right_photo_2"
                ></content-item>
                <content-item
                    class="third"
                    :config="content.right_photo_3"
                ></content-item>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
import ContentVideo from "../../content/ContentVideo.vue";
import Modal from "../../Modal.vue";
export default {
    name: "welcome-complex",
    data() {
        let vm = this;
        return {
            isVideoOpen: false,
            videoIsPlaying: false,
            videoCompleteCallback: function() {
                vm.complete();
                vm.closeVideoModal();
            }
        };
    },
    mounted() {
        let imageCount = 6;
        let curImageCount = 0;

        // Don't have the photos move in until they are all loaded.
        $(".photos > img").on("load", function() {
            if (++curImageCount === imageCount) {
                $(".photos > img").toggleClass("active");
            }
        });
    },
    methods: {
        openVideoModal() {
            this.isVideoOpen = true;
            this.videoIsPlaying = true;
        },
        closeVideoModal() {
            this.videoIsPlaying = false;
            this.isVideoOpen = false;
        },
        afterComplete() {
            // Unlock the first module.
            if (this.modules.length > 0) {
                this.modules[0].locked = false;
            }
        }
    },
    computed: {
        lastCompleted() {
            return this.$store.state.lastCompleted;
        },
        allModulesComplete() {
            let complete = true;
            $(this.$store.state.sections).each(function() {
                $(this.modules).each(function() {
                    if (this.completed_at === null) {
                        complete = false;
                        return false;
                    }
                });
            });

            return complete;
        }
    },
    mixins: [SectionMixin],
    components: {
        Modal,
        ContentVideo
    }
};
</script>
