<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";
@import "@/scss/breakpoints";

.welcome-simple-template {
    background-color: #00b2ed;
}

.welcome-complex-template {
    background-color: #00b2ed;
}

.modules-template {
}

.customer-testimonials-template {
    background-color: $primary-color;
}

.contact-us-template {
    background-color: $background-grey;
}

.faq-template {
    background-color: $background-grey;
}

.nav-summary {
    display: flex;
    justify-content: center;
}

#main-page .scroll-down {
    position: fixed;
    top: calc(50% - 40px);
    z-index: 99;

    @media (max-width: 767px) {
        top: 50px;
        display: none;
    }
}

.page-fade-enter-active,
.page-fade-leave-active {
    transition: opacity 0.2s;
}
.page-fade-enter-from,
.page-fade-leave-to {
    opacity: 0;
}

// Need to make sure container does not overlap fixed nav-summary-mobile
@media (max-width: calc(map-get($grid-breakpoints, "md") - 1px)) {
    #main-container {
        padding-top: 60px;
    }
}
</style>

<template>
    <div id="main-page" tabindex="0" v-if="pageContent" @wheel="scrollHandler">
        <nav-header :pageContent="pageContent" :displayMenu="1"></nav-header>
        <div class="nav-summary" v-if="showMainSections">
            <nav-summary></nav-summary>
        </div>
        <nav-summary-mobile v-if="showMainSections"></nav-summary-mobile>
        <div
            id="main-container"
            class="container-fluid"
            v-show="showMainSections"
        >
            <pagepiling :parent-section-index="currentSectionIndex">
                <section-wrapper
                    v-for="(section, index) in $store.getters.sections"
                    :key="index"
                    :class="section.template + '-template'"
                    :hasBackground="index === 0"
                >
                    <component
                        :is="section.template"
                        :section="section"
                        :content="section.content"
                        :completedAt="section.completed_at"
                        :modules="section.modules"
                        :sectionId="section.id"
                        :sectionIndex="index + 1"
                    ></component>
                </section-wrapper>
            </pagepiling>
        </div>

        <transition name="page-fade" mode="out-in">
            <reward-page v-if="showRewardPage"></reward-page>
            <crowdsourcing-page
                v-else-if="showCrowdsourcingPage"
            ></crowdsourcing-page>
        </transition>

        <div class="scroll-down" v-if="showMainSections">
            <arrow-controls
                :parentSectionIndex="currentSectionIndex"
            ></arrow-controls>
        </div>

        <testimonial-footer
            :isVisible="showTestimonialBar"
        ></testimonial-footer>
        <icon-footer></icon-footer>
        <privacy-popup
            v-if="shouldShowPrivacyPopup"
            :message="t('privacy_popup_message')"
            :privacyLinkText="t('privacy_link_text')"
            :cookieLinkText="t('cookie_link_text')"
            :buttonText="t('privacy_popup_button_text')"
            @dismiss="onDismissPrivacyPopup"
        />
        <profile-popup
            v-else-if="shouldShowProfilePopup"
            @dismiss="onDismissProfilePopup"
        />
    </div>
</template>

<script type="text/babel">
import { mapMutations, mapGetters, mapState } from "vuex";
import PageSection from "../mixins/PageSection";
import NavHeader from "../NavHeader.vue";
import TestimonialFooter from "../TestimonialFooter.vue";
import Pagepiling from "../PagePiling.vue";
import SectionWrapper from "../SectionWrapper.vue";
import ArrowControls from "../sections/ArrowControls.vue";
import ControlsMixin from "../mixins/ControlsMixin";
import RewardPage from "./RewardPage.vue";
import CrowdsourcingPage from "./CrowdsourcingPage";
import WelcomeSimple from "../sections/templates/WelcomeSimple.vue";
import WelcomeComplex from "../sections/templates/WelcomeComplex.vue";
import Modules from "../sections/templates/Modules.vue";
import CustomerTestimonials from "../sections/templates/CustomerTestimonial.vue";
import ContactUs from "../sections/templates/ContactUs.vue";
import Faq from "../sections/templates/Faq.vue";
import ProgramLandingPage from "../sections/templates/ProgramLandingPage.vue";
import ModuleLandingPage from "../sections/templates/ModuleLandingPage.vue";
import PartnerSplashPage from "../pages/PartnerSplashPage.vue";
import IconFooter from "../IconFooter.vue";
import NavSummary from "../NavSummary.vue";
import NavSummaryMobile from "../NavSummaryMobile.vue";
import PrivacyPopup from "../PrivacyPopup.vue";
import ProfilePopup from "@/components/ProfilePopup";

// Import all section template components
// Foreach incoming section, find the correct template component, and put it in a page piling with the corresponding data.

export default {
    name: "main-page",
    mixins: [ControlsMixin],
    computed: {
        ...mapState([
            "showRewardPage",
            "showCrowdsourcingPage",
            "showTestimonialBar"
        ]),
        ...mapGetters(["pageContent", "currentSectionIndex"]),
        showMainSections() {
            return (
                this.$store.state.showRewardPage === false &&
                this.$store.state.showCrowdsourcingPage === false
            );
        },
        hasSeenSplashPage() {
            return this.$store.state.user.has_seen_splash;
        },
        shouldShowPrivacyPopup() {
            return (
                !this.$store.state.user.has_accepted_privacy &&
                !this.$store.state.user.is_impersonated
            );
        },
        shouldShowProfilePopup() {
            return (
                Object.hasOwn(this.$store.state.user, "has_updated_profile") &&
                !this.$store.state.user.has_updated_profile &&
                !this.$store.state.user.is_impersonated
            );
        }
    },
    async mounted() {
        const {
            id,
            has_accepted_privacy,
            has_updated_profile
        } = this.$store.getters.user;

        if (id && has_accepted_privacy && has_updated_profile) {
            if (this.checkSplashPageStatus()) {
                return;
            }
        }

        if (this.$store.getters.contentResponse) {
            PageSection.methods.setData(this.$store.getters.contentResponse);
        } else {
            let response = await this.$http.get("/api/content/");
            this.$store.commit("setContentResponse", response.data);
            PageSection.methods.setData(this.$store.getters.contentResponse);
        }

        let section = this.$store.getters.sections[
            this.$store.getters.currentSectionIndex
        ];
        this.trackGA(section);

        await this.loadCrowdsourcingSections();
    },
    watch: {
        currentSectionIndex(newValue) {
            if (newValue === 0) {
                this.$store.commit("setResetModules", true);
                // If all modules are complete, then go to module index = 0
                // Else, go to the latest incomplete module.
                //this.$store.commit('setCurrentModuleIndex', this.$store.getters.activeModuleIndex);
            } else {
                // Determine hash tag name for section from template
                // Get current section
                let section = this.$store.state.sections[newValue];
                this.trackGA(section);
            }
        }
    },
    data() {
        return {
            pageName: this.$store.getters.currentPage,
            scrollThrottle: false,
            timeout: null,
            privacyPopupDismissed: false,
            profilePopupDismissed: false
        };
    },
    methods: {
        ...mapMutations(["setCrowdsourcingSections"]),
        trackGA(section) {
            let path = this.$route.path;

            gtag("config", dataLayer[1][1], {
                page_title: section.template,
                page_location: path,
                page_path: path,
                send_to: "pageview"
            });
        },
        t(translationName) {
            const content = this.pageContent.Main.content[translationName];

            if (!content) {
                return "";
            }

            return content.translation;
        },
        checkSplashPageStatus() {
            if (!this.hasSeenSplashPage) {
                this.$router.push("/user/splash");
                return true;
            }
            return false;
        },
        onDismissPrivacyPopup() {
            this.privacyPopupDismissed = true;
            window.userData.user.has_accepted_privacy = true;
            this.checkSplashPageStatus();
        },
        onDismissProfilePopup() {
            this.profilePopupDismissed = true;
            this.checkSplashPageStatus();
        },
        scrollHandler(e) {
            // Disable the scroll while popups are displayed.
            if (
                (this.shouldShowProfilePopup && !this.profilePopupDismissed) ||
                (this.shouldShowPrivacyPopup && !this.privacyPopupDismissed)
            ) {
                return;
            }
            /* const debounce = (func, wait, immediate) => {

				return () => {
					const context = this,
						args = arguments;
					let later = () => {
						timeout = null;
						if (!immediate) func.apply(context, args);
					};

					const callNow = immediate && !timeout;
					clearTimeout(timeout)
				};
			}; */
            if (!this.scrollThrottle) {
                if (e.deltaY > 0) {
                    this.down();
                } else {
                    this.up();
                }
                this.scrollThrottle = true;

                clearTimeout(this.timeOut);

                this.timeOut = setTimeout(() => {
                    this.scrollThrottle = false;
                }, 500);
            }
        },
        async loadCrowdsourcingSections() {
            let response = await this.$http.get("/api/content/crowdsourcing");
            this.setCrowdsourcingSections(response.data.sections);
        }
    },
    components: {
        NavSummaryMobile,
        NavSummary,
        IconFooter,
        NavHeader,
        TestimonialFooter,
        Pagepiling,
        ArrowControls,
        RewardPage,
        SectionWrapper,
        WelcomeSimple,
        WelcomeComplex,
        Modules,
        CustomerTestimonials,
        ContactUs,
        Faq,
        ProgramLandingPage,
        PartnerSplashPage,
        ModuleLandingPage,
        PrivacyPopup,
        ProfilePopup,
        CrowdsourcingPage
    }
};
</script>
