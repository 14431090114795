export default {
    data() {
        return {
            activeItemIndex: 0,
            items: []
        };
    },
    methods: {
        setActiveItemIndex(index) {
            this.activeItemIndex = index;
        },
        next() {
            if (this.activeItemIndex < this.items.length - 1) {
                // Move to next tab.
                this.activeItemIndex++;
            } else {
                // Move to next section if we are at the last tab.
                this.nextSection();
            }
        },
        previous() {
            if (this.activeItemIndex > 0) {
                this.activeItemIndex--;
            } else {
                this.previousSection();
            }
        },
        handleReset() {
            this.activeItemIndex = 0;
        }
    }
};
