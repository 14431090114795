const getUrlPath = url => {
    if (url.startsWith("/")) {
        return url.split("?")[0];
    }

    try {
        const urlObj = new URL(url);
        return urlObj.pathname;
    } catch (error) {
        return url;
    }
};

export default {
    data: () => ({
        isTrackingAsset: false
    }),
    methods: {
        onTrackableLinkClick(e) {
            const { href } = e.currentTarget;

            if (this.isTrackableAsset(href)) {
                // Force asset to open in new window to ensure current window continues to track asset.
                // WARNING: Updating target doesn't work on production site (makes no sense) so cannot be relied on.
                e.currentTarget.target = "_blank";
                this.trackAsset(href);
            }
        },
        onTrackableVideoPlay(source) {
            if (this.isTrackableAsset(source)) {
                this.trackAsset(source);
            }
        },
        isTrackableAsset(url) {
            const path = getUrlPath(url);

            return path.startsWith("/assets/");
        },
        trackAsset(url) {
            const path = getUrlPath(url);

            this.isTrackingAsset = true;

            // Assuming this request is fast, otherwise need something to indicate to the user that we're blocking until
            // the request finishes.
            return this.$http
                .post("/assets/track", {
                    url: path
                })
                .finally(() => {
                    this.isTrackingAsset = false;
                });
        }
    }
};
