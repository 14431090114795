<template>
    <div class="resource-layout-page">
        <nav-header
            :displayMenu="1"
            :toggleMenu="menu"
            :pageContent="pageContent"
        ></nav-header>
        <div
            class="resource-page-wrapper"
            :style="{ 'background-image': 'url(' + getBackgroundImage + ')' }"
        >
            <div class="resource-container content-container">
                <div class="resource-layout-content">
                    <h1 class="header-container" v-html="t('title')"></h1>
                    <hr />
                    <component
                        v-for="(section, index) in sections"
                        :is="section.template"
                        :key="section.id"
                        :content="section.content"
                        :modules="section.modules"
                    ></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavHeader from "../NavHeader.vue";
import PageSection from "../mixins/PageSection";
import Modal from "../../components/Modal.vue";
import ResourcesSection from "../sections/templates/ResourcesSection.vue";
export default {
    components: {
        ResourcesSection,
        NavHeader,
        Modal
    },
    name: "resource-layout-page",
    created() {
        this.$http.get("/api/content/resource").then(
            response => {
                if (response.data.success === true) {
                    PageSection.methods.setData(response);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    data() {
        return {
            pageName: "Resources"
        };
    },
    methods: {
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        }
    },
    computed: {
        ...mapGetters(["sections", "pageContent"]),
        getBackgroundImage() {
            return this.$store.getters.getBackground;
        },
        content() {
            if (
                this.pageContent != null &&
                typeof this.pageContent[this.pageName] !==
                    "undefined"
            )
                return this.pageContent[this.pageName].content;
            return "";
        },
        menu() {
            if (!this.$store.getters.user.id) {
                return {
                    intro: false,
                    epix: false,
                    profile: false,
                    resource: false,
                    contact: true,
                    privacy: true,
                    terms: false,
                    logout: false
                };
            } else {
                return false;
            }
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.resource-layout-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    .resource-page-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        overflow: hidden;

        background-color: $background-color;
        background-size: cover;
        background-position: center 0;
    }
    .resource-container {
        height: 100%;
        overflow: auto;
        justify-content: flex-start;
    }
    .resource-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;
    }

    h1.header-container {
        font-size: 40px;
        padding: 30px;
        line-height: 50px;
        margin-top: 30px;
        //font-weight: bold;
    }

    .body-container {
        width: 50%;
        color: white;
        padding-top: 15px;
        text-align: center;
        justify-content: center;
        align-content: center;
        font-size: 16px;
        display: inline-block;
        flex-wrap: wrap;
    }
    a.box {
        width: 100%;
        height: 50px;
        //margin: 10px 15px;
        background-color: transparent;
        //color: #58595b;
        display: flex;
        //text-decoration: none;
    }
    .inline {
        width: 50px;
        padding: 5px 8px;
        display: inline-block;
    }
    .copy {
        flex: 1;
        color: white;
        cursor: pointer;
        text-decoration: underline;
        line-height: 48px;
        text-align: left;
        padding-left: 0px;
        overflow: none;
    }
}

@media (max-width: 767px) {
    .resource-layout-page .resource-container {
        width: 100%;
    }
    .resource-layout-page .resource-layout-content {
        width: 85%;
    }
    .resource-layout-page h1.header-container {
        padding: 0px;
        font-size: 30px;
    }
    .body-container {
        width: 100% !important;
    }
    .resource-layout-page a.box {
        width: 100%;
    }
    .resource-layout-page .copy {
        line-height: 20px;
        padding-top: 11px;
    }
}
@media (max-width: 1024px) {
    .resource-layout-page .copy {
        line-height: 20px;
        padding-top: 12px;
    }

    .resource-layout-page .resource-container {
        width: 90%;
    }
}
</style>
