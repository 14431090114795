<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.policy-modal-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    table td {
        border-collapse: collapse;
        border: 1px solid lightgrey;
        padding: 5px;
    }

    .policy-modal-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.header-container {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .body-container {
            width: 100%;
            font-size: 16px;
            text-align: left;
        }
    }

    @media (max-width: 767px) {
        h1 {
        }
    }
}
</style>

<template>
    <div class="policy-modal-page">
        <div class="policy-modal-content">
            <h1 class="header-container" v-html="t('title')"></h1>
            <div class="body-container" v-html="t('body')"></div>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: "policy-modal-page",
    props: {
        pageName: String
    },
    computed: {
        pageContent() {
            return this.$store.state.pageContent;
        },
        content() {
            if (
                this.$store.state.pageContent != null &&
                typeof this.$store.state.pageContent[this.pageName] !==
                    "undefined"
            )
                return this.$store.state.pageContent[this.pageName].content;
            return "";
        }
    },
    methods: {
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        }
    }
};
</script>
