<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.faq-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .faq-page {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1 {
            color: white;
            margin-bottom: 50px;
        }

        .accordion-item {
            border-bottom: 3px solid $support-border-grey;
            color: white;
        }

        .accordion-footer {
            margin-top: 15px;
            font-size: 13px;
            color: white;
            text-align: center;
            a {
                text-decoration: underline;
            }
        }

        .form-container {
            width: 100%;
            max-width: 512px;
        }

        .support-buttons {
            width: 100%;
        }

        .support-button {
            box-sizing: border-box;
            color: white;
            @extend .support-button-font;
            line-height: 1.3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 0;
            padding: 0;

            .arrow {
                background-image: url("../../../assets/images/step-button-arrow-right.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        .form {
            height: 0;
            transition: all 0.5s linear;
            transition-property: height, visibility;
            visibility: hidden;
            border-top: none;
            overflow: hidden;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: white;
        }

        .form-container:not(.active) {
            .support-button {
                border: 3px solid $support-border-grey;
            }

            .support-button:not(.active, .submit-button) {
                transition: all 0s step-end;
                transition-property: visibility, height, padding, border-width;
                visibility: visible;
                transition-delay: 0.5s;
                /*height: 100%;*/
                height: 82px;
                padding: 25px 25px 25px 35px;
            }

            .support-button:not(:first-child):last-child {
                border-top-width: 0;
            }

            .submit-button {
                height: 0;
                padding: 0;
                border-width: 0;

                transition: all 0s step-end;
                transition-property: visibility, height, padding, border-width;
                visibility: hidden;
                transition-delay: 0.5s;
            }
        }

        .form-container.active {
            .support-button:not(.active, .submit-button) {
                visibility: hidden;
                border-width: 0;
            }

            .support-button.active {
                .arrow {
                    transform: rotate(90deg);
                }
            }

            .support-button.active,
            .submit-button {
                /*height: 100%;*/
                height: 82px;
                padding: 25px 25px 25px 35px;
            }

            .support-button:not(.submit-button) {
                border: 3px solid $support-border-grey;
            }

            .submit-button {
                visibility: visible;
            }

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
                transition: all 0.5s linear;
                transition-property: height, display;

                textarea {
                    transition: overflow 0.5s linear;
                }
            }
        }

        @media (max-width: 767px) {
            h1 {
                margin-bottom: 40px;
            }
        }
    }

    .footer {
        height: 100px;
        background-color: $primary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 60px;
        flex-shrink: 0;

        .copy {
            max-width: 50%;
            font-size: 10px;
        }

        .logo {
            background-image: url("../../../assets/images/logo.png");
            width: 46px;
            height: 46px;
            background-size: cover;
        }

        @media (max-width: 767px) {
            height: auto;
            flex-direction: column;
            padding: 20px;
            .copy {
                font-size: 10px;
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}
</style>

<template>
    <div class="faq-wrapper">
        <div class="faq-page">
            <div class="expander"></div>
            <h1>
                <content-item :config="content.header"></content-item>
            </h1>

            <div
                v-if="!submitSuccessful"
                :class="[{ active: isFormOpen }]"
                class="form-container"
            >
                <div class="support-buttons">
                    <div
                        v-for="formType in formTypes"
                        v-if="formType.content.translation.trim()"
                        :class="[
                            {
                                active:
                                    activeFormType !== null &&
                                    formType.name === activeFormType
                            }
                        ]"
                        @click="toggleForm(formType)"
                        @touchmove.stop=""
                        class="support-button"
                    >
                        <content-item :config="formType.content"></content-item>
                        <div class="arrow"></div>
                    </div>
                </div>
                <div class="form">
                    <content-item
                        :config="currentAccordion"
                        v-if="isFormOpen"
                    ></content-item>
                    <div class="accordion-footer">
                        <content-item
                            :config="currentFooter"
                            v-if="isFormOpen"
                        ></content-item>
                    </div>
                </div>
            </div>

            <div class="expander"></div>
            <arrow-controls :parentSectionIndex="sectionIndex"></arrow-controls>
        </div>
        <div class="footer">
            <div class="copy">{{ t("Footer", "Disclaimer", 0) }}</div>
            <div class="logo"></div>
        </div>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
import TranslationMixin from "../../mixins/TranslationMixin";
import ReplaceMixin from "../../mixins/ReplaceMixin";
export default {
    name: "faq",
    data() {
        return {
            isFormOpen: false,
            activeFormType: null,
            formTypes: [
                {
                    name: "technical_issues_dropdown",
                    content: this.content.technical_issues_dropdown,
                    accordion: this.content.technical_issues_accordion,
                    footer: this.replaceData(
                        Object.assign({}, this.content.technical_issues_footer),
                        {
                            "dropdownmenu.translate.english":
                                "{{contentData.content.technical_issues_dropdown}}"
                        }
                    )
                },
                {
                    name: "other_issues_dropdown",
                    content: this.content.other_issues_dropdown,
                    accordion: this.content.other_issues_accordion,
                    footer: this.replaceData(
                        Object.assign({}, this.content.other_issues_footer),
                        {
                            "dropdownmenu.translate.english":
                                "{{contentData.content.technical_issues_dropdown}}"
                        }
                    )
                }
            ],
            currentAccordion: null,
            currentFooter: null,
            submitSuccessful: false
        };
    },
    methods: {
        toggleForm(type) {
            if (this.isFormOpen && this.activeFormType === type.name) {
                this.isFormOpen = false;
                this.activeFormType = null;
                this.currentAccordion = null;
                this.currentFooter = null;
            } else {
                this.isFormOpen = true;
                this.activeFormType = type.name;
                this.currentAccordion = type.accordion;
                this.currentFooter = type.footer;
            }
        }
    },
    mixins: [SectionMixin, ReplaceMixin, TranslationMixin]
};
</script>
