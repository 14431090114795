<style lang="scss" rel="stylesheet/scss">
video {
    width: 100%;
    display: block;
}
.video-js vjs-default-skin vjs-16-9 {
    margin-left: 40%;
    margin-top: 22%;
}
.video {
    &::cue,
    &::-webkit-media-text-track-display-backdrop {
        // if this doesn't work then own implementation will need
        // https://stackoverflow.com/questions/32252337/how-to-style-text-tracks-in-html5-video-via-css
        background-color: rgba(83, 86, 90, 0.7) !important;
        margin-left: 40%;
        margin-top: 22%;
    }
}
</style>

<template>
    <video controls ref="player" @ended="ended" class="video" preload="none">
        <source
            v-if="config.mp4Source !== null"
            :src="config.mp4Source"
            type="video/mp4"
        />
        <source
            v-if="config.webmSource !== null"
            :src="config.webmSource"
            type="video/webm"
        />

        <track
            v-for="track in tracks"
            :src="track.source"
            :key="track.locale"
            :srclang="track.locale"
            :label="track.title"
            :default="
                track.locale === defaultLanguage && defaultLanguage == 'es'
            "
        />
    </video>
</template>

<script type="text/babel">
export default {
    name: "content-video",
    props: ["config", "onComplete", "modelValue"],
    emits: ["update:modelValue"],
    data() {
        return {
            isReady: false
        };
    },
    watch: {
        isReady(value) {
            if (value && this.modelValue) {
                this.play();
            }
        },
        modelValue(newVal) {
            if (newVal) {
                this.play();
            } else {
                this.stop();
            }
        }
    },
    methods: {
        play() {
            this.player.play();
        },
        stop() {
            this.player.pause();
            //this.player.currentTime(0);
        },
        ended() {
            this.$emit("update:modelValue", false);
            if (typeof this.onComplete !== "undefined") {
                this.onComplete();
            }
        }
    },
    computed: {
        defaultLanguage() {
            return this.$store.state.user.language;
        },
        player() {
            return this.$refs.player;
        },
        tracks() {
            if (Array.isArray(this.config.tracks)) {
                return this.config.tracks.filter(
                    track => track.source !== null
                );
            }

            return [];
        }
    }
};
</script>
