import eventBus from "@/event-bus";

export default {
    computed: {
        currentModuleIndex() {
            // note: the current section index starts at 0
            return this.$store.state.currentModuleIndex;
        },
        currentSectionIndex() {
            // note: the current section index starts at 0
            return this.$store.state.currentSectionIndex;
        },
        sectionCount() {
            return this.$store.state.sections.length;
        },
        moduleCount() {
            if (
                typeof this.$store.state.sections[this.currentSectionIndex] ==
                "undefined"
            ) {
                return 0;
            }
            return (
                this.$store.state.sections[this.currentSectionIndex].modules
                    .length || 0
            );
        },
        canUp() {
            return this.currentSectionIndex > 0;
        },
        canUpModule() {
            return this.currentModuleIndex > 0 || false;
        },
        canDown() {
            return this.currentSectionIndex < this.sectionCount - 1;
        },
        canDownModule() {
            return this.currentModuleIndex + 1 < this.moduleCount || false;
        },
        isLeftArrowVisible() {
            return (
                this.$store.state.arrows.left.visibile &&
                (this.canUp || this.canUpModule)
            );
        },
        isRightArrowVisible() {
            return (
                this.$store.state.arrows.right.visibile &&
                (this.canDown || this.canDownModule)
            );
        }
    },
    methods: {
        up() {
            if (typeof this.$store.state.arrows.left.action === "function") {
                this.$store.state.arrows.left.action();
            } else if (this.canUpModule) {
                this.gotoSectionModule(
                    this.currentSectionIndex,
                    this.currentModuleIndex - 1
                );
            } else if (this.canUp) {
                //finding the previous section to see if it has modules
                let nextModuleCount =
                    this.$store.state.sections[this.currentSectionIndex - 1]
                        .modules.length || 0;

                if (nextModuleCount > 0) {
                    this.gotoSectionModule(
                        this.currentSectionIndex - 1,
                        nextModuleCount - 1
                    );
                } else {
                    this.gotoSectionModule(this.currentSectionIndex - 1);
                }
            }
        },
        down() {
            if (typeof this.$store.state.arrows.right.action === "function") {
                this.$store.state.arrows.right.action();
            } else if (this.canDownModule) {
                this.gotoSectionModule(
                    this.currentSectionIndex,
                    this.currentModuleIndex + 1
                );
            } else if (this.canDown) {
                //finding the previous section to see if it has modules
                let nextModuleCount =
                    this.$store.state.sections[this.currentSectionIndex + 1]
                        .modules.length || 0;
                if (nextModuleCount > 0) {
                    this.gotoSectionModule(this.currentSectionIndex + 1, 0);
                } else {
                    this.gotoSectionModule(this.currentSectionIndex + 1);
                }
            }
        },
        gotoSectionModule: function(sectionIndex, moduleIndex) {
            if (
                typeof this.$store.state.sections[sectionIndex] === "undefined"
            ) {
                return;
            }
            if (typeof moduleIndex !== "undefined") {
                if (
                    typeof this.$store.state.sections[sectionIndex].modules[
                        moduleIndex
                    ] === "undefined"
                ) {
                    return;
                }
                // do we want to lock the page from being selected.. or just show the "locked" page
                // if(this.$store.state.sections[sectionIndex].modules[moduleIndex].locked) {
                //     return;
                // }
                this.$store.commit("setCurrentModuleIndex", moduleIndex);
            }
            if (typeof sectionIndex !== "undefined") {
                this.$store.commit("setCurrentSectionIndex", sectionIndex);
                if (typeof moduleIndex === "undefined") {
                    this.$store.commit("setCurrentModuleIndex", null);
                }
            }

            eventBus.$emit("section-change");

            // running after action
            if (
                typeof this.$store.state.arrows.goto.afterAction === "function"
            ) {
                this.$store.state.arrows.goto.afterAction();
                this.setOnceAfterGotoSectionModule(null);
            }
        },
        findActiveModuleIndex: function() {
            return this.$store.getters.activeModuleSectionIndex;
        },
        getAllSectionModules: function() {
            return this.$store.getters.allSectionModules;
        },
        setOnceAfterGotoSectionModule(value) {
            this.$store.commit("setAfterGotoAction", value);
        },
        setRightArrowAction(value) {
            this.$store.commit("setRightArrowAction", value);
        },
        showRightArrow() {
            this.$store.commit("setRightArrowVisibility", true);
        },
        hideRightArrow() {
            this.$store.commit("setRightArrowVisibility", false);
        },
        setLeftArrowAction(value) {
            this.$store.commit("setLeftArrowAction", value);
        },
        showLeftArrow() {
            this.$store.commit("setLeftArrowVisibility", true);
        },
        hideLeftArrow() {
            this.$store.commit("setLeftArrowVisibility", false);
        }
    }
};
