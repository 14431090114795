<template>
    <div :class="['profile-popup', { open: open }]">
        <transition name="slide-fade" @enter="doInitialFocus">
            <div
                v-show="open"
                @keydown="onKeyPress"
                class="profile-popup--dialog"
                role="alertdialog"
                aria-describedby="profile-popup--dialog-heading"
                aria-modal="true"
            >
                <a
                    v-show="showClose"
                    class="close"
                    href="#"
                    @click.prevent="close()"
                    role="button"
                    aria-label="Close"
                    ref="closeButton"
                    >X</a
                >
                <p
                    id="profile-popup--dialog-heading"
                    class="heading"
                    v-html="t('heading')"
                ></p>
                <ValidationForm
                    class="default"
                    @submit="updateProfile"
                    ref="form"
                >
                    <div class="form-group">
                        <Field
                            :label="t('location_label')"
                            name="location_designation"
                            rules="required"
                            v-slot="{
                                field,
                                errorMessage,
                                meta: { valid, validated }
                            }"
                            v-model="profile.location_designation"
                        >
                            <select
                                v-bind="field"
                                ref="initialFocus"
                                :class="{ 'is-invalid': validated && !valid }"
                            >
                                <option :value="null" disabled>{{
                                    t(
                                        locationOptions.length
                                            ? "location_label"
                                            : "loading_text"
                                    )
                                }}</option>
                                <option
                                    v-for="location in locationOptions"
                                    :key="location.id"
                                    :value="location.id"
                                    >{{ location.name }}</option
                                >
                            </select>
                            <div v-if="errorMessage" class="error">
                                {{ errorMessage }}
                            </div>
                        </Field>
                    </div>
                    <div class="form-group">
                        <Field
                            :label="t('title_label')"
                            name="title"
                            rules="required"
                            v-slot="{
                                field,
                                errorMessage,
                                meta: { valid, validated }
                            }"
                            v-model="profile.title"
                        >
                            <select
                                v-bind="field"
                                :class="{ 'is-invalid': validated && !valid }"
                            >
                                <option value="" disabled>{{
                                    t("title_label")
                                }}</option>
                                <option
                                    v-for="role in roleOptions"
                                    :key="role"
                                    :value="role"
                                    >{{ role }}</option
                                >
                            </select>
                            <div v-if="errorMessage" class="error">
                                {{ errorMessage }}
                            </div>
                        </Field>
                    </div>
                    <div class="form-group">
                        <Field
                            :label="t('address1_label')"
                            name="address1"
                            rules="required"
                            v-slot="{
                                field,
                                errorMessage,
                                meta: { valid, validated }
                            }"
                            v-model="profile.address1"
                        >
                            <input
                                v-bind="field"
                                type="text"
                                :placeholder="t('address1_label')"
                                :class="{ 'is-invalid': validated && !valid }"
                            />
                            <div v-if="errorMessage" class="error">
                                {{ errorMessage }}
                            </div>
                        </Field>
                    </div>
                    <div class="form-group">
                        <input
                            type="text"
                            name="address2"
                            :placeholder="t('address2_label')"
                            v-model="profile.address2"
                        />
                    </div>
                    <div class="form-group">
                        <Field
                            :label="t('city_label')"
                            name="city"
                            rules="required"
                            v-slot="{
                                field,
                                errorMessage,
                                meta: { valid, validated }
                            }"
                            v-model="profile.city"
                        >
                            <input
                                v-bind="field"
                                type="text"
                                placeholder="City"
                                :class="{ 'is-invalid': validated && !valid }"
                            />
                            <div v-if="errorMessage" class="error">
                                {{ errorMessage }}
                            </div>
                        </Field>
                    </div>
                    <div class="form-row-sm">
                        <div class="form-group form-group--state">
                            <Field
                                :label="t('state_label')"
                                name="state"
                                rules="required"
                                v-slot="{
                                    field,
                                    errorMessage,
                                    meta: { valid, validated }
                                }"
                                v-model="profile.state"
                            >
                                <select
                                    v-bind="field"
                                    :class="{
                                        'is-invalid': validated && !valid
                                    }"
                                >
                                    <option value="" disabled>{{
                                        t("state_label")
                                    }}</option>
                                    <option
                                        v-for="state in stateOptions"
                                        :key="state"
                                        :value="state"
                                        >{{ state }}</option
                                    >
                                </select>
                                <div v-if="errorMessage" class="error">
                                    {{ errorMessage }}
                                </div>
                            </Field>
                        </div>
                        <div class="form-group">
                            <Field
                                :label="t('zip_label')"
                                name="zip"
                                rules="required|digits:5"
                                v-slot="{
                                    field,
                                    errorMessage,
                                    meta: { valid, validated }
                                }"
                                v-model="profile.zip"
                            >
                                <input
                                    v-bind="field"
                                    type="text"
                                    :placeholder="t('zip_label')"
                                    :class="{
                                        'is-invalid': validated && !valid
                                    }"
                                />
                                <div v-if="errorMessage" class="error">
                                    {{ errorMessage }}
                                </div>
                            </Field>
                        </div>
                    </div>
                    <loading-button
                        ref="saveButton"
                        type="button"
                        v-model="saving"
                        @click="updateProfile"
                        >{{ t("button_text") }}</loading-button
                    >
                </ValidationForm>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { roleOptions } from "@/helpers/roles";
import LoadingButton from "@/components/buttons/LoadingButton";
import { Form as ValidationForm, Field } from "vee-validate";

const defaultTranslations = {
    heading: "Please update your information.",
    location_label: "Location",
    title_label: "Role",
    address1_label: "Address 1",
    address2_label: "Address 2",
    city_label: "City",
    state_label: "State",
    zip_label: "Zip",
    button_text: "Save",
    loading_text: "Loading"
};

export default {
    name: "ProfilePopup",
    components: {
        LoadingButton,
        ValidationForm,
        Field
    },
    props: {
        // If wanting to show the close button, probably just toggle the default here
        showClose: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        profile: {},
        open: false,
        saving: false
    }),
    created() {
        // Get initial data from store
        this.profile = {
            ...this.user
        };

        // If the user's current location isn't in the options, set to null to force selection of new value.
        if (
            !this.locationOptions.find(
                location => location.id === this.user.location_designation
            )
        ) {
            this.profile.location_designation = null;
        }
    },
    mounted() {
        this.open = true;
        this.$refs.initialFocus.focus();
    },
    computed: {
        ...mapGetters(["user", "translations"]),
        locationOptions() {
            return this.user.location_options || [];
        },
        roleOptions() {
            return roleOptions;
        },
        stateOptions() {
            return window.formData.state || [];
        }
    },
    methods: {
        ...mapMutations(["setUserLoadStatus"]),
        t(key) {
            return (
                this.translations.profile_popup?.[key] ??
                defaultTranslations[key]
            );
        },
        close() {
            this.open = false;
            this.$emit("dismiss");
        },
        async updateProfile() {
            this.saving = true;
            const { valid } = await this.$refs.form.validate();

            if (!valid) {
                this.saving = false;
                return;
            }

            try {
                const formData = new FormData(this.$refs.form.$el);
                await this.$http.post(
                    "/api/user/profile/takeover",
                    Object.fromEntries(formData.entries())
                );

                // Doing this so the next route change will freshen user data from server.
                // We could, instead, commit the updates to the client store.
                this.setUserLoadStatus(0);
                this.close();
            } catch (error) {
                if (error.response.data.errors) {
                    this.$refs.form.setErrors(
                        error.response.data.errors
                    );
                }
            } finally {
                this.saving = false;
            }
        },
        doInitialFocus() {
            this.$refs.initialFocus.focus();
        },
        onKeyPress(e) {
            // Trap Focus
            const isTab = e.key === "Tab" || e.keyCode === 9;

            if (!isTab) {
                return;
            }

            if (e.shiftKey) {
                if (
                    document.activeElement === this.$refs.closeButton ||
                    (!this.showClose &&
                        document.activeElement === this.$refs.initialFocus)
                ) {
                    this.$refs.saveButton.$el.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === this.$refs.saveButton.$el) {
                    if (this.showClose) {
                        this.$refs.closeButton.focus();
                    } else {
                        this.$refs.initialFocus.focus();
                    }

                    e.preventDefault();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/scss/color";
@import "@/scss/breakpoints";
@import "@/scss/helper/_mixin";

.profile-popup {
    // This box sizing should be global!!!
    * {
        box-sizing: border-box;
    }

    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s;
    display: flex;

    &.open {
        opacity: 1;
        visibility: visible;
    }

    &.open:before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 70%);
        pointer-events: none;
        z-index: -1;
    }

    .profile-popup--dialog {
        position: relative;
        width: 95%;
        border: 1px solid black;
        background-color: $silver;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up("sm") {
            padding: 4rem 4rem 2rem 4rem;
        }

        @include media-breakpoint-up("md") {
            width: 650px;
        }

        .close {
            color: black;
            font-weight: bold;
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem;
            cursor: pointer;
            text-decoration: none;

            @include input-outline;
        }

        .heading {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 1.4375rem;

            @include media-breakpoint-up("sm") {
                font-size: 21px;
            }
        }

        @include media-breakpoint-up("sm") {
            .form-group--state {
                flex-basis: 50%;
            }
        }
    }
}
</style>
