<style lang="scss" rel="stylesheet/scss"></style>

<template>
    <div>Page Not Found</div>
</template>

<script type="text/babel">
export default {
    name: "not-found-page",
    data() {
        return {};
    }
};
</script>
