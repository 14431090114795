<template>
    <div
        class="video-outer-wrapper"
        :class="{ 'video-modal': playing }"
        @click.self="stop"
    >
        <div class="video-inner-wrapper">
            <video
                controls
                class="video-player"
                ref="player"
                @ended="end"
                @play.once="onVideoPlay"
            >
                <source :src="videoSource" :type="`video/${extension}`" />
            </video>
            <div
                class="video-image"
                :style="{ backgroundImage: `url(${posterImage})` }"
                v-if="showImage"
                @click="play"
            >
                <div class="play"></div>
            </div>
        </div>
    </div>
</template>
<script>
import TrackAssetMixin from "@/components/mixins/TrackAssetMixin";

export default {
    name: "VideoPlayer",
    mixins: [TrackAssetMixin],
    props: {
        posterImage: String,
        videoSource: String,
        extension: String,
        trackPlays: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showImage: true,
            playing: false
        };
    },
    methods: {
        play() {
            this.playing = true;
            this.showImage = false;
            this.$refs.player.play();
        },
        end() {
            this.showImage = true;
        },
        stop() {
            this.playing = false;
            this.$refs.player.pause();
            this.showImage = true;
        },
        onVideoPlay() {
            if (this.trackPlays) {
                this.onTrackableVideoPlay(this.videoSource);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
/**
 * The flex outer wrapper with a padding-top on the inner wrapper allows for us to maintain the same aspect ratio on the video player.
 */
.video-outer-wrapper {
    flex: 0 1 800px;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;

    .video-inner-wrapper {
        width: 100%;
        padding-top: 56.179%; //16:9 aspect

        .video-image {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 2;
            background-color: #000;
            background-position: center;
            background-size: cover;
            color: #fff;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;

            .play {
                width: 150px;
                height: 150px;
                background-image: url("~@/assets/images/video-play-button.png");
                background-size: contain;
                opacity: 0.8;
                cursor: pointer;
            }

            .play:hover {
                opacity: 1;
            }
        }

        .video-player {
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 1;
        }
    }
}

@media (max-width: 650px) {
    .video-outer-wrapper.video-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9999;
        display: flex;

        .video-inner-wrapper {
            width: 80%;
            position: relative;
            padding-top: calc(56.179% * 0.8);
            margin: auto;
        }
    }
}
</style>
