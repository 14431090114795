<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";
@import "@/scss/helper/_nav";
@import "@/scss/breakpoints";

.nav-summary-mobile-component {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    z-index: 99;
    top: $nav-header-height-mobile;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    font-size: 25px;
    display: none;

    .toggle {
        position: fixed;
        right: 15%;
        color: white;
    }
    .control-btn {
        position: fixed;
        width: 30px;
        color: white;
    }
    .right-btn {
        right: 10px;
    }
    .left-btn {
        left: 10px;
    }
    .nav-summary-mobile-item {
        color: lightgrey;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        width: 100%;

        &.locked {
            color: white;
            opacity: 0.6;
        }

        &.complete {
            color: white;
        }

        &.active {
            color: white;
        }

        &.nav-summary-mobile-module {
            font-size: 16px;
        }

        &.nav-summary-mobile-section:first {
            border: none;
        }
        &.nav-summary-mobile-section.show-all {
            border-top: 2px solid lightgrey;
        }
        &.show-all {
            padding: 3px;
        }
    }
    &.show-all {
        justify-content: flex-start;
        height: 100%;
    }

    @media (max-width: calc(map-get($grid-breakpoints, "md") - 1px)) {
        display: flex;
    }
}
</style>

<template>
    <div
        class="nav-summary-mobile-component"
        v-bind:class="{ 'show-all': showAll }"
        v-if="showStatusbar"
    >
        <div @click="setToggle()" v-if="showAll" class="toggle">&and;</div>
        <div @click="setToggle()" v-else class="toggle">&or;</div>
        <div
            @click="up()"
            v-show="!showAll && isLeftArrowVisible"
            class="control-btn left-btn"
        >
            &lt;
        </div>
        <div
            @click="down()"
            v-show="!showAll && isRightArrowVisible"
            class="control-btn right-btn"
        >
            &gt;
        </div>
        <div
            v-for="(nav, index) in sectionModules"
            :class="[
                'nav-summary-mobile-item',
                { locked: nav.locked },
                { 'show-all': showAll },
                { complete: isNavComplete(nav, index) },
                { active: isNavActive(nav, index) },
                {
                    'nav-summary-mobile-section-active': isSectionActive(
                        nav,
                        index
                    )
                },
                { 'nav-summary-mobile-module': nav.type === 'module' },
                { 'nav-summary-mobile-section': nav.type === 'section' }
            ]"
            @click="setActiveNav(nav, index)"
            @touchmove.stop=""
        >
            <div class="hover-text" v-html="nav.title"></div>
        </div>
    </div>
</template>

<script type="text/babel">
import ControlsMixin from "./mixins/ControlsMixin";
export default {
    name: "nav-summary-mobile",
    props: [],
    data() {
        return {
            titleIndex: null,
            showAll: false
        };
    },
    computed: {
        sectionModules() {
            return this.getAllSectionModules().filter(
                (nav, index) => this.isNavActive(nav, index) || this.showAll
            );
        },
        showStatusbar() {
            return this.$store.state.showStatusBar;
        }
    },
    methods: {
        isSectionActive(nav, index) {
            if (nav.type === "section") {
                if (nav.sectionIndex == this.currentSectionIndex) {
                    return true;
                }
                // assuming the previous section is the "ModuleLandingPage"
                if (this.currentSectionIndex - 1 == nav.sectionIndex) {
                    return true;
                }
            }
            return false;
        },
        isNavHidden(nav, index) {
            if (nav.type === "module") {
                if (nav.sectionIndex == this.currentSectionIndex) {
                    return false;
                }
                // assuming the previous section is the "ModuleLandingPage"
                if (this.currentSectionIndex + 1 == nav.sectionIndex) {
                    return false;
                }

                return true;
            }
            return false;
        },
        isNavLocked(nav, index) {
            return nav.completed_at !== null;
        },
        isNavActive(nav, index) {
            let activeIndex = this.findActiveModuleIndex();
            if (
                nav.type === "section" &&
                this.currentSectionIndex == nav.sectionIndex &&
                this.currentModuleIndex === null
            ) {
                return true;
            } else if (
                nav.type === "module" &&
                this.currentSectionIndex == nav.sectionIndex &&
                this.currentModuleIndex == nav.moduleIndex
            ) {
                return true;
            }
            return false;
        },
        isNavComplete(nav, index) {
            let activeIndex = this.findActiveModuleIndex();
            if (nav.type === "section") {
                return nav.completed_at !== null;
            } else if (
                nav.type === "module" &&
                typeof nav.quizComplete !== "undefined"
            ) {
                return nav.completed_at !== null && nav.quiz.isComplete;
            }
            return false;
        },
        setActiveNav(nav, index) {
            let moduleIndex = null;
            if (typeof nav.moduleIndex !== "undefined") {
                moduleIndex = nav.moduleIndex;
                this.gotoSectionModule(
                    parseInt(nav.sectionIndex),
                    parseInt(moduleIndex)
                );
            } else {
                this.gotoSectionModule(parseInt(nav.sectionIndex));
            }
            this.setToggle();
        },
        setToggle() {
            if (this.showAll) {
                this.showAll = false;
            } else {
                this.showAll = true;
            }
        }
    },
    mixins: [ControlsMixin]
};
</script>
