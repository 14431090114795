<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";

#footer-logo {
    z-index: 100;
    width: 200px;
    min-height: 47px;
    position: absolute;
    bottom: 80px;
    right: 20px;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
        display: none;
    }
}
</style>

<template>
    <div
        id="footer-logo"
        v-if="setFooterLogoImage.footerLogoImageURL"
        :style="{ backgroundImage: 'url(' + buildFooterLogoImageURL() + ')' }"
    ></div>
</template>

<script type="text/babel">
import FooterLogoImage from "./mixins/FooterLogoImage";
export default {
    name: "icon-footer",
    data() {
        return {};
    },
    methods: {
        buildFooterLogoImageURL() {
            return this.setFooterLogoImage.footerLogoImageURL;
        }
    },
    mixins: [FooterLogoImage],
    components: {}
};
</script>
