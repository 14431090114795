<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.arrow-controls {
    .arrows {
        display: flex;
    }

    .arrow {
        cursor: pointer;
        width: 82px;
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        &.left {
            position: fixed;
            left: 30px;

            .arrow-icon {
                background-image: url("../../assets/images/arrow-left.png");
                width: 82px;
                height: 82px;
                background-size: cover;
            }
        }

        &.right {
            position: fixed;
            right: 30px;

            .arrow-icon {
                background-image: url("../../assets/images/arrow-right.png");
                width: 82px;
                height: 82px;
                background-size: cover;
            }
        }
    }
    @media (max-width: 767px) {
        .arrow {
            &.left {
                left: 0px;
            }
            &.right {
                right: 0px;
            }
            .arrow-icon {
                transform: scale(0.6);
            }
        }
    }
}
</style>

<template>
    <div class="arrow-controls">
        <div class="arrows">
            <div
                v-if="isLeftArrowVisible"
                @click="up()"
                @touchmove.stop=""
                class="arrow-wrapper"
            >
                <div class="arrow left">
                    <div class="arrow-icon"></div>
                </div>
            </div>
            <div
                v-if="isRightArrowVisible"
                @click="down()"
                @touchmove.stop=""
                class="arrow-wrapper"
            >
                <div class="arrow right">
                    <div class="arrow-icon"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import ControlsMixin from "../mixins/ControlsMixin";
export default {
    name: "arrow-controls",
    props: ["parentSectionIndex"],
    mixins: [ControlsMixin]
};
</script>
