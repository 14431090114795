<style lang="scss" rel="stylesheet/scss">
.module.activity-pdf {
    max-width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    flex-direction: row;
    align-items: stretch;
    padding: 0;

    .content-container {
        align-items: center;

        // Need extra spacing to not run into absolutely positioned nav summary
        padding-top: 80px;
    }

    a {
        text-decoration: none !important;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
        font-size: 16px;
    }
    .center-item {
        width: 190px;
        height: 190px;
        position: fixed;
    }

    .image-splash {
        max-width: 100%;
        max-height: 100%;
    }

    .image-button-container {
        padding-top: 25px;
        padding-bottom: 21px;
        max-width: 508px;
        width: 100%;
        margin: 20px !important;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .view-pdf {
        text-decoration: none;
    }
}
</style>

<template>
    <div
        class="module activity-pdf"
        :style="{ 'background-image': 'url(' + getModuleBackgroundImage + ')' }"
        v-if="showActivity"
    >
        <div class="content-container">
            <h1>
                <content-item :config="content.title"></content-item>
            </h1>
            <p>
                <content-item :config="content.subheader"></content-item>
            </p>

            <div class="image-button-container">
                <a
                    class="view-pdf"
                    :href="content.resource.fileUrl"
                    :target="content.resource.fileUrl"
                    rel="noopener noreferrer"
                >
                    <img
                        @click="watchedPdf"
                        class="image-splash"
                        v-if="content.image"
                        :src="content.image.source"
                        :alt="content.image.altText"
                    />
                </a>
            </div>
            <a
                v-if="!isViewPdf"
                :href="content.resource.fileUrl"
                :target="content.resource.fileUrl"
                rel="noopener noreferrer"
            >
                <button v-on:click="watchedPdf" class="button">
                    <content-item :config="content.button"></content-item>
                </button>
            </a>
            <button v-if="isViewPdf" class="button" v-on:click="showQuiz">
                <content-item
                    v-if="quizComplete"
                    :config="content.buttonquizcomplete"
                ></content-item>
                <content-item
                    v-else
                    :config="content.buttoncomplete"
                ></content-item>
            </button>
        </div>
    </div>
    <quiz-component
        class="module activity-pdf"
        :style="{ 'background-image': 'url(' + getModuleBackgroundImage + ')' }"
        v-else
        :quizData="quizData"
        :quizId="quizId"
    ></quiz-component>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
import QuizMixin from "../../mixins/QuizMixin";
import QuizComponent from "../../quiz/QuizComponent.vue";
export default {
    components: { QuizComponent },
    name: "activity-template-pdf",
    data() {
        return {
            showActivity: true,
            isPdfOpen: false,
            isViewPdf: false,
            quizId: this.content.quiz_id.translation,
            background: this.content.splash.source
        };
    },
    methods: {
        showActivities() {
            this.endQuizNavigation();
            this.showActivity = true;
        },
        openPdfModal() {
            this.isPdfOpen = true;
        },
        showQuiz() {
            this.startQuizNavigation(this.showActivities);
            this.showActivity = false;
        },
        watchedPdf() {
            this.isViewPdf = true;
        }
    },
    computed: {
        getModuleBackgroundImage() {
            return this.background;
        }
    },
    mixins: [ModuleMixin, QuizMixin]
};
</script>
