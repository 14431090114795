<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.module.final {
    text-align: center;

    .outer-section {
        width: 100%;
        max-width: 820px;
    }

    p {
        font-size: 12px;
        line-height: 1.1;
    }

    .buttons {
        margin-top: 10px;
    }

    .inner-section {
        width: 100%;
        max-width: 600px;
        background-color: $primary-color;
        border-radius: 8px;
        padding: 10px 30px 20px 30px;
        margin-top: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            @extend .final-secondary-heading;
        }

        h2,
        p {
            width: 100%;
        }
    }

    .accept-button {
        background-color: $grey;
        color: $primary-color;
        border-radius: 8px;
        padding: 7px 35px;
        margin-top: 20px;
        cursor: pointer;
        border: 1px solid $button-blue;

        @extend .step-button-font;
    }

    .complete {
        margin-top: 20px;
    }

    @media (max-width: 767px) {
        .inner-section {
            padding: 30px 20px;
        }
    }
}
</style>

<template>
    <div class="module final">
        <div class="outer-section">
            <h1>
                <content-item :config="content.header"></content-item>
            </h1>
            <p>
                <content-item :config="content.content"></content-item>
            </p>
        </div>
        <div class="inner-section">
            <h2>
                <content-item :config="content.secondary_header"></content-item>
            </h2>
            <p>
                <content-item
                    :config="content.secondary_content"
                ></content-item>
            </p>

            <template v-if="hasAcceptButton">
                <div
                    v-if="!isAccepted"
                    class="accept-button"
                    @touchmove.stop=""
                    @click="accept()"
                >
                    <content-item
                        :config="content.accept_button"
                    ></content-item>
                </div>
                <div class="complete" v-else>
                    <content-item
                        :config="content.accept_button_complete"
                    ></content-item>
                    {{ dateComplete }}
                </div>
            </template>
        </div>

        <div class="buttons">
            <step-button
                @click="previousSection()"
                direction="left"
                v-if="hasPrevious"
            ></step-button>
            <step-button
                @click="nextSection()"
                v-if="hasNext && isComplete"
            ></step-button>
        </div>
    </div>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
export default {
    name: "module-final",
    data() {
        return {
            hasAcceptButton: this.template === "module-final-accept-button",
            isAccepted: this.completedAt !== null
        };
    },
    methods: {
        onLastModule() {
            if (this.hasAcceptButton) {
                // Do nothing. We need to watch the video to complete the module.
                return;
            }
            // Complete the module if there is no accept button.
            this.nextSection();
        },
        accept() {
            let vm = this;
            this.complete(() => {
                vm.isAccepted = true;
                window.location.reload();
            });
        }
    },
    mixins: [ModuleMixin]
};
</script>
