<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";
@import "@/scss/font";

.grey-box {
    position: relative;
    background-color: $grey;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle {
    //border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.module.tabbed-content {
    h2 {
        @extend .tabbed-content-header;
    }

    .subheading {
        margin-bottom: 40px;
    }

    .grey-box {
        width: 100%;
        max-width: 840px;
        padding-top: 60px;
        padding-bottom: 10px;
        box-sizing: border-box;
    }

    .tabs {
        top: -20px;
        position: absolute;
        padding: 0 5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    .box-content {
        text-align: center;
    }

    .buttons {
        margin-top: 10px;
    }

    .tab {
        @extend .circle;
        width: 76px;
        height: 76px;
        background-color: $grey;
        margin: 0 5px;
        cursor: pointer;

        .inner-circle {
            @extend .circle;
            @extend .circle-text;
            width: 62px;
            height: 62px;
            background-color: white;
            padding-top: 8px;
            box-sizing: border-box;
        }
    }

    .tab.active {
        .inner-circle {
            background-color: black;
            color: $primary-color;
        }
    }

    @media (max-width: 767px) {
        .subheading {
            margin-bottom: 40px;
        }

        .grey-box {
            max-width: 420px;
            min-height: 97.5px;
            padding: 30px 10px 12.5px;
        }

        .tabs {
            top: -10px;
            padding: 0 2.5px;
        }

        .box-content {
            text-align: center;
        }

        .tab {
            width: 38px;
            height: 38px;

            .inner-circle {
                width: 31px;
                height: 31px;
                padding-top: 4px;
            }
        }
    }
}
</style>

<template>
    <div class="module tabbed-content">
        <h1>
            <content-item :config="content.header"></content-item>
        </h1>
        <p class="subheading">
            <content-item :config="content.content"></content-item>
        </p>
        <div class="grey-box">
            <div class="tabs">
                <div
                    class="tab"
                    v-for="n in content.tabbed_carousel.items.length"
                    @touchmove.stop=""
                    @click="setActiveItemIndex(n - 1)"
                    :class="{ active: n - 1 === activeItemIndex }"
                >
                    <div class="inner-circle">{{ n }}</div>
                </div>
            </div>
            <div class="box-content">
                <div
                    v-for="(item, key) in content.tabbed_carousel.items"
                    v-if="key == activeItemIndex"
                    class="box-item"
                >
                    <content-item :config="item"></content-item>
                </div>
            </div>
        </div>

        <div class="buttons">
            <step-button
                @click="previous()"
                direction="left"
                v-if="hasPrevious"
            ></step-button>
            <step-button @click="next()" v-if="hasNext"></step-button>
        </div>
    </div>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
import ActiveItemsMixin from "../ActiveItemsMixin";

export default {
    name: "module-tabbed-content",
    data() {
        return {
            items: this.content.tabbed_carousel.items
        };
    },
    mixins: [ModuleMixin, ActiveItemsMixin]
};
</script>
