import ContentItem from "../content/ContentItem.vue";
import ArrowControls from "./ArrowControls.vue";

export default {
    props: [
        "completedAt",
        "modules",
        "sectionId",
        "section",
        "sectionIndex",
        "content"
    ],
    computed: {
        isComplete() {
            return this.completedAt !== null;
        }
    },
    methods: {
        complete() {
            let vm = this;

            // If current section is not complete, then make request to server to complete it
            if (this.completedAt !== null) return;

            this.$http
                .post("/api/section/complete", { sectionId: this.sectionId })
                .then(
                    response => {
                        if (response.data.success === true) {
                            vm.$store.commit("completeSection", {
                                id: vm.sectionId,
                                completed_at: response.data.completed_at
                            });
                            vm.$store.commit(
                                "setLastCompleted",
                                vm.section.title
                            );
                            vm.$store.commit(
                                "updateModuleStatuses",
                                response.data.module_statuses
                            );
                            if (typeof vm.afterComplete !== "undefined") {
                                vm.afterComplete();
                            }
                        } else {
                            throw new Error("Could not complete section.");
                        }
                    },
                    response => {
                        throw new Error("Error completed section.");
                    }
                );
        }
    },
    components: {
        ContentItem,
        ArrowControls
    }
};
