<template>
    <div v-if="content">
        <nav-header :displayMenu="1" :toggleMenu="menu"></nav-header>
        <section-wrapper class="landing-wrapper">
            <div class="content-container">
                <div class="landing-content">
                    <div class="landing-upper">
                        <div class="video-container">
                            <video-player
                                :videoSource="
                                    content.intro_video.mp4Source
                                        ? content.intro_video.mp4Source
                                        : content.intro_video.webmSource
                                "
                                :extension="
                                    content.intro_video.mp4Source
                                        ? 'mp4'
                                        : 'webm'
                                "
                                :posterImage="content.intro_video_image.source"
                                track-plays
                            />
                        </div>
                        <div class="copy-container">
                            <h1>
                                <span
                                    v-html="
                                        content.landing_headline.translation
                                    "
                                />
                            </h1>
                            <span
                                v-html="content.landing_content.translation"
                            />
                        </div>
                        <div class="links-container resource-links">
                            <router-link :to="{ name: 'resources' }">
                                <div class="link">
                                    <div class="link-image">
                                        <img
                                            :src="content.video_icon.source"
                                            :alt="content.video_icon.altText"
                                        />
                                    </div>
                                    <div class="link-copy">
                                        <span
                                            v-html="
                                                content.video_link_label
                                                    .translation
                                            "
                                        />
                                    </div>
                                </div>
                            </router-link>
                            <a
                                :class="[
                                    'link-block',
                                    { disabled: this.isTrackingAsset }
                                ]"
                                :href="
                                    content.programming_highlights_pdf.fileUrl
                                "
                                target="_blank"
                                rel="noopener noreferrer"
                                @click="onTrackableLinkClick"
                            >
                                <div class="link">
                                    <div
                                        class="link-image"
                                        :class="{
                                            'icon-pdf': !content.pdf_icon.source
                                        }"
                                    >
                                        <img
                                            :src="content.pdf_icon.source"
                                            :alt="content.pdf_icon.altText"
                                        />
                                    </div>
                                    <div class="link-copy">
                                        <span
                                            v-html="
                                                content
                                                    .programming_highlights_pdf_label
                                                    .translation
                                            "
                                        />
                                    </div>
                                </div>
                            </a>
                            <a
                                :class="[
                                    'link-block',
                                    { disabled: this.isTrackingAsset }
                                ]"
                                :href="content.job_aid_pdf.fileUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                                @click="onTrackableLinkClick"
                            >
                                <div class="link">
                                    <div
                                        class="link-image"
                                        :class="{
                                            'icon-pdf': !content.pdf_icon.source
                                        }"
                                    >
                                        <img
                                            v-if="content.pdf_icon.source"
                                            :src="content.pdf_icon.source"
                                            :alt="content.pdf_icon.altText"
                                        />
                                    </div>
                                    <div class="link-copy">
                                        <span
                                            v-html="
                                                content.job_aid_pdf_label
                                                    .translation
                                            "
                                        />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="links-container">
                            <router-link class="button" :to="{ name: 'login' }">
                                <span v-html="content.login_link.translation" />
                            </router-link>
                            <router-link class="button" to="/user/register/1">
                                <span
                                    v-html="content.register_link.translation"
                                />
                            </router-link>
                        </div>
                    </div>
                    <div class="footer-container">
                        <div class="footer-copy">
                            <span v-html="content.landing_footer.translation" />
                        </div>
                    </div>
                </div>
            </div>
        </section-wrapper>
    </div>
</template>
<script>
import NavHeader from "../NavHeader.vue";
import PageSection from "../mixins/PageSection";
import SectionWrapper from "../SectionWrapper";
import TrackAssetMixin from "@/components/mixins/TrackAssetMixin";
import VideoPlayer from "../VideoPlayer";

export default {
    name: "LandingPage",
    mixins: [TrackAssetMixin],
    components: { NavHeader, SectionWrapper, VideoPlayer },
    created() {
        this.$http.get("/api/content/page").then(
            ({ data: response }) => {

                if (response.success === true) {
                    PageSection.methods.setData(response);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    data() {
        return {
            menu: {
                intro: false,
                epix: false,
                profile: false,
                resource: true,
                contact: true,
                privacy: true,
                terms: false,
                logout: false
            }
        };
    },
    computed: {
        content() {
            return this.$store.state.pageContent?.Landing?.content;
        }
    },
    methods: {
        getExt(file) {
            let n = file.lastIndexOf(".");
            return n === -1 ? "web" : file.slice(n + 1);
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/scss/color.scss";

.landing-wrapper {
    flex: 1 0;
    display: flex;

    h1 {
        margin: 20px;
        color: #000;
    }
}

.landing-content {
    :deep(a:not(.button)) {
        color: #000;
    }

    .video-container {
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }

    .copy-container {
        color: #000;
        text-align: center;
        margin: 40px 20px;
    }

    .links-container {
        display: flex;
        justify-content: space-around;
        margin: 40px 20px;
        align-items: center;

        .link-block {
            &.disabled {
                pointer-events: none;
            }
        }

        .link {
            display: flex;

            .link-copy {
                font-size: 1.1em;
                text-decoration: underline;
                display: flex;
                justify-content: space-around;
                align-items: center;
            }

            .link-image {
                max-width: 72px;
                max-height: 72px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin: 0 10px;
                overflow: hidden;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: auto;
                    height: auto;
                    max-width: 72px;
                }
            }
        }

        .button {
            margin: 10px 0;
        }
    }

    .footer-container {
        justify-self: flex-end;
        flex: 0 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .footer-copy {
            text-align: center;

            a {
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 1199px) {
    .landing-content {
        flex-basis: 75%;
    }
}

@media (max-width: 767px) {
    .landing-content {
        flex-basis: 100%;
    }
}

@media (max-width: 650px) {
    .links-container {
        flex-direction: column;

        .link {
            margin: 10px;

            .link-image {
                width: 72px;
                height: 72px;
            }
        }
    }

    .resource-links {
        align-items: flex-start !important;
    }
}
</style>
