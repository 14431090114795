<style lang="scss" rel="stylesheet/scss"></style>

<template>
    <img :src="config.source" :alt="config.altText" />
</template>

<script type="text/babel">
export default {
    name: "content-image",
    props: ["config"]
};
</script>
