<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.contact-us-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    .contact-us-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.header-header {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .body-container {
            width: 100%;
            max-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
        }
        .form {
            margin-bottom: 25px;
            width: 100%;
        }
        textarea {
            width: 400px;
            height: 100px;
        }

        .support-button {
            box-sizing: border-box;
            color: white;
            @extend .support-button-font;
            line-height: 1.3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 0;
            padding: 0;

            .arrow {
                background-image: url("../../assets/images/step-button-arrow-right.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }
    }

    @media (max-width: 767px) {
        h1 {
        }
    }
}
</style>

<template>
    <div class="contact-us-page">
        <div class="contact-us-content">
            <h1 class="header-container">Contact Us</h1>

            <div class="body-container">
                <div
                    v-if="!submitSuccessful"
                    :class="[{ active: isFormOpen }]"
                    class="form-container"
                >
                    <div class="form">
                        <textarea
                            placeholder="Explain Iusse"
                            v-model="question"
                        ></textarea>
                    </div>
                    <div
                        class="submit-button support-button"
                        @touchmove.stop=""
                        @click="submit()"
                    >
                        Submit
                        <div class="arrow"></div>
                    </div>
                </div>
                <div v-if="isFormOpen" class="errors">
                    <template v-for="errorSet in errors">
                        <div v-for="error in errorSet">{{ error }}</div>
                    </template>
                </div>

                <template v-if="submitSuccessful">
                    <p v-if="activeFormType === 'other_support'">
                        Thank you for your submission
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: "contact-us-page",
    computed: {
        pageContent() {
            return this.$store.state.pageContent;
        },
        content() {
            if (
                this.$store.state.pageContent != null &&
                typeof this.$store.state.pageContent[this.pageName] !==
                    "undefined"
            )
                return this.$store.state.pageContent[this.pageName].content;
            return "";
        }
    },
    data() {
        return {
            pageName: "ContactUs",
            isFormOpen: true,
            activeFormType: "other_support",
            formTypes: [
                {
                    name: "other_support",
                    content: "Plese submit your information"
                }
            ],
            question: "",
            errors: [],
            submitSuccessful: false
        };
    },
    methods: {
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        },
        toggleForm(type) {
            if (this.isFormOpen && this.activeFormType === type) {
                this.isFormOpen = false;
                this.activeFormType = null;
            } else {
                this.isFormOpen = true;
                this.activeFormType = type;
            }
        },
        submit() {
            this.$http
                .post("/api/contact", {
                    type: this.activeFormType,
                    question: this.question
                })
                .then(
                    response => {
                        if (response.data.success === true) {
                            this.submitSuccessful = true;
                        } else {
                            this.errors = response.data.errors;
                        }
                    },
                    response => {
                        throw new Error("Error making request.");
                    }
                );
        }
    },
    watch: {
        isFormOpen(newValue) {
            if (newValue === false) {
                // Remove errors on close.
                this.errors = [];
            }
        }
    }
};
</script>
