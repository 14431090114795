<style lang="scss" rel="stylesheet/scss"></style>

<template>
    <component :is="'content-' + config.type" :config="config"></component>
</template>

<script type="text/babel">
import ContentImage from "./ContentImage.vue";
import ContentLink from "./ContentLink.vue";
import ContentVideo from "./ContentVideo.vue";
import ContentText from "./ContentText.vue";
import ContentAccordion from "./ContentAccordion.vue";

export default {
    name: "content-item",
    props: ["config", "onComplete"],
    mounted() {},
    components: {
        ContentImage,
        ContentLink,
        ContentVideo,
        ContentText,
        ContentAccordion
    }
};
</script>
