<style lang="scss" rel="stylesheet/scss">
.resource-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    .resource-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.header-container {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .body-container {
            width: 100%;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
        }
        a.box {
            width: 300px;
            height: 50px;
            border: 1px solid #ccc;
            margin: 10px 15px;
            background-color: transparent;
            color: #58595b;
            display: flex;
            text-decoration: none;
        }
        .inline {
            width: 50px;
            border-right: 1px solid #ccc;
            padding: 5px 8px;
            display: inline-block;
        }
        .copy {
            flex: 1;
            line-height: 48px;
            text-align: left;
            padding-left: 25px;
            overflow: none;
        }
    }

    @media (max-width: 767px) {
        h1 {
        }
    }
}
</style>

<template>
    <div class="resource-page">
        <div class="resource-content">
            <h1 class="header-container" v-html="t('title')"></h1>

            <div class="body-container">
                <a
                    v-for="(resource, idx) in resources"
                    :key="idx"
                    :href="resource.body.translation"
                    class="box"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div class="inline">
                        <div
                            :class="[
                                'icon',
                                'icon-' + getExt(resource.body.translation)
                            ]"
                        ></div>
                    </div>
                    <div class="copy" v-html="resource.title"></div>
                </a>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: "resource-page",
    computed: {
        pageContent() {
            return this.$store.state.pageContent;
        },
        files() {
            return typeof this.content.files !== "undefined"
                ? this.content.files
                : [];
        },
        content() {
            if (
                this.$store.state.pageContent != null &&
                typeof this.$store.state.pageContent[this.pageName] !==
                    "undefined"
            )
                return this.$store.state.pageContent[this.pageName].content;
            return "";
        },
        resources() {
            return this.content.category_list_viewer.items;
        }
    },
    data() {
        return {
            pageName: "Resources"
        };
    },
    methods: {
        getExt(file) {
            let n = file.lastIndexOf(".");
            return n === -1 ? "web" : file.slice(n + 1);
        },
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        }
    }
};
</script>
