<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.contact-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .contact-us-page {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1 {
            color: white;
            margin-bottom: 50px;
        }

        .form-container {
            width: 100%;
            max-width: 512px;
        }

        .support-buttons {
            width: 100%;
        }

        .support-button {
            box-sizing: border-box;
            color: white;
            @extend .support-button-font;
            line-height: 1.3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 0;
            padding: 0;

            .arrow {
                background-image: url("../../../assets/images/step-button-arrow-right.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        .form {
            height: 0;
            transition: all 0.5s linear;
            transition-property: height, visibility;
            border: 3px solid $support-border-grey;
            visibility: hidden;
            border-top: none;
            overflow: hidden;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: white;
        }

        .form-container:not(.active) {
            .support-button {
                border: 3px solid $support-border-grey;
            }

            .support-button:not(.active, .submit-button) {
                transition: all 0s step-end;
                transition-property: visibility, height, padding, border-width;
                visibility: visible;
                transition-delay: 0.5s;
                /*height: 100%;*/
                height: 82px;
                padding: 25px 25px 25px 35px;
            }

            .support-button:not(:first-child):last-child {
                border-top-width: 0;
            }

            .submit-button {
                height: 0;
                padding: 0;
                border-width: 0;

                transition: all 0s step-end;
                transition-property: visibility, height, padding, border-width;
                visibility: hidden;
                transition-delay: 0.5s;
            }
        }

        .form-container.active {
            .support-button:not(.active, .submit-button) {
                visibility: hidden;
                border-width: 0;
            }

            .support-button.active {
                .arrow {
                    transform: rotate(90deg);
                }
            }

            .support-button.active,
            .submit-button {
                /*height: 100%;*/
                height: 82px;
                padding: 25px 25px 25px 35px;
            }

            .support-button:not(.submit-button) {
                border: 3px solid $support-border-grey;
            }

            .submit-button {
                visibility: visible;
            }

            .form {
                visibility: visible;
                border-top: none;
                height: 277px;
                transition: all 0.5s linear;
                transition-property: height, display;

                textarea {
                    transition: overflow 0.5s linear;
                }
            }
        }

        textarea {
            @extend .support-button-font;
            height: 100%;
            box-sizing: border-box;
            resize: none;
            width: 100%;
            border: none;
            padding: 35px;
            overflow: hidden;
        }

        .errors {
            width: 100%;
            max-width: 512px;
            color: red;
            margin-top: 10px;
        }

        @media (max-width: 767px) {
            h1 {
                margin-bottom: 40px;
            }
        }
    }

    .footer {
        height: 100px;
        background-color: $primary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 60px;
        flex-shrink: 0;

        .copy {
            max-width: 50%;
            font-size: 10px;
        }

        .logo {
            background-image: url("../../../assets/images/logo.png");
            width: 46px;
            height: 46px;
            background-size: cover;
        }

        @media (max-width: 767px) {
            height: auto;
            flex-direction: column;
            padding: 20px;
            .copy {
                font-size: 10px;
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}
</style>

<template>
    <div class="contact-wrapper">
        <div class="contact-us-page">
            <div class="expander"></div>
            <h1>
                <content-item :config="content.header"></content-item>
            </h1>

            <div
                v-if="!submitSuccessful"
                :class="[{ active: isFormOpen }]"
                class="form-container"
            >
                <div class="support-buttons">
                    <div
                        v-for="formType in formTypes"
                        v-if="formType.content.translation.trim()"
                        :class="[
                            {
                                active:
                                    activeFormType !== null &&
                                    formType.name === activeFormType
                            }
                        ]"
                        @click="toggleForm(formType.name)"
                        @touchmove.stop=""
                        class="support-button"
                    >
                        <content-item :config="formType.content"></content-item>
                        <div class="arrow"></div>
                    </div>
                </div>
                <div class="form">
                    <textarea
                        :placeholder="content.explain_issue.translation"
                        v-model="question"
                    ></textarea>
                </div>
                <div
                    class="submit-button support-button"
                    @touchmove.stop=""
                    @click="submit()"
                >
                    <content-item :config="content.submit"></content-item>
                    <div class="arrow"></div>
                </div>
            </div>
            <div v-if="isFormOpen" class="errors">
                <template v-for="errorSet in errors">
                    <div v-for="error in errorSet">{{ error }}</div>
                </template>
            </div>

            <template v-if="submitSuccessful">
                <p v-if="activeFormType === 'technical_support'">
                    <content-item
                        :config="content.complete_technical_support"
                    ></content-item>
                </p>
                <p v-if="activeFormType === 'other_support'">
                    <content-item
                        :config="content.complete_other_support"
                    ></content-item>
                </p>
            </template>
            <div class="expander"></div>
            <arrow-controls :parentSectionIndex="sectionIndex"></arrow-controls>
        </div>
        <div class="footer">
            <div class="copy">{{ t("Footer", "Disclaimer", 0) }}</div>
            <div class="logo"></div>
        </div>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
import TranslationMixin from "../../mixins/TranslationMixin";
export default {
    name: "contact-us",
    data() {
        return {
            isFormOpen: false,
            activeFormType: null,
            formTypes: [
                {
                    name: "technical_support",
                    content: this.content.technical_support
                },
                {
                    name: "other_support",
                    content: this.content.other_support
                }
            ],
            question: "",
            errors: [],
            submitSuccessful: false
        };
    },
    methods: {
        toggleForm(type) {
            if (this.isFormOpen && this.activeFormType === type) {
                this.isFormOpen = false;
                this.activeFormType = null;
            } else {
                this.isFormOpen = true;
                this.activeFormType = type;
            }
        },
        submit() {
            this.$http
                .post("/api/contact", {
                    type: this.activeFormType,
                    question: this.question
                })
                .then(
                    response => {
                        if (response.data.success === true) {
                            this.submitSuccessful = true;
                        } else {
                            this.errors = response.data.errors;
                        }
                    },
                    response => {
                        throw new Error("Error making request.");
                    }
                );
        }
    },
    watch: {
        isFormOpen(newValue) {
            if (newValue === false) {
                // Remove errors on close.
                this.errors = [];
            }
        }
    },
    mixins: [SectionMixin, TranslationMixin]
};
</script>
