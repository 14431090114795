<style lang="scss" rel="stylesheet/scss"></style>

<template>
    <router-link
        :to="config.url"
        v-if="config.url.charAt(0) == '/' && config.target != ''"
        :target="config.target"
        v-html="config.text"
    ></router-link>
    <a
        :href="config.url"
        v-else-if="config.target != ''"
        rel="noopener noreferrer"
        :target="config.target"
        v-html="config.text"
    ></a>
    <router-link
        :to="config.url"
        v-else-if="config.url.charAt(0) == '/'"
        v-html="config.text"
    ></router-link>
    <a :href="config.url" v-else="config.target != ''" v-html="config.text"></a>
</template>

<script type="text/babel">
export default {
    name: "content-link",
    props: ["config"]
};
</script>
