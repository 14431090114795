<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.quiz-summary {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 8px;

    .quiz-item {
        border-radius: 50%;
        border: 3px solid #000;
        width: 15px;
        height: 15px;
        margin-right: 15px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &.locked {
            background-color: transparent;
            border-color: $quiz-summary-circle-locked;
            opacity: 0.6;
        }

        &.complete {
            background-color: #e0e0e0;
        }

        &.active {
            background-color: transparent;

            .inner {
                border-radius: 50%;
                box-sizing: border-box;
                width: 7px;
                height: 7px;
                background-color: $quiz-summary-circle;
            }
        }

        .hover-text {
            font-size: 13px;
            line-height: 1.3;
            text-align: center;
            position: absolute;
            top: 15px;
            margin: auto;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
        }
    }

    .quiz-item:last-child {
        margin-right: 0;
    }

    @media (max-width: 767px) {
        margin-top: 10px;

        .quiz-item {
            border-width: 1.5px;
            width: 10.5px;
            height: 10.5px;
            margin-right: 7.5px;

            &.active {
                .inner {
                    width: 6.5px;
                    height: 6.5px;
                }
            }

            .hover-text {
                font-size: 8px;
                line-height: 1.3;
                width: 60px;
                top: 15px;
            }
        }
    }
}
</style>

<template>
    <div class="quiz-summary">
        <div
            v-for="(question, index) in questions"
            :class="[
                'quiz-item',
                { locked: isQuestionLocked(question, index) },
                { complete: question.user.is_complete },
                { active: activeQuestionId === question.id }
            ]"
            @click="setActiveQuestion(question, index)"
            @touchmove.stop=""
            @mouseover="showTitle(question, index)"
            @mouseout="hideTitle(question, index)"
        >
            <div class="inner"></div>
            <div
                class="hover-text"
                v-show="titleIndex === index"
                v-html="question.title"
            ></div>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: "quiz-summary",
    props: ["activeQuestionId", "quizId"],
    data() {
        return {
            titleIndex: null
        };
    },
    computed: {
        questions() {
            return this.$store.state.quizData[this.quizId].questions;
        }
    },
    methods: {
        isQuestionLocked(question, index) {
            return index !== 0 && !this.questions[index - 1].user.is_complete;
        },
        setActiveQuestion(question, index) {
            if (!this.isQuestionLocked(question, index)) {
                this.$emit("changeQuestion", index);
            }
        },
        showTitle(question, index) {
            this.titleIndex = index;
        },
        hideTitle(question, index) {
            this.titleIndex = null;
        }
    }
};
</script>
