import translator from "../../api/translator";
import ControlsMixin from "./ControlsMixin";
import store from "../../store";

export default {
    data() {
        return {
            //sectionData: this.$store.state.sections,
            localIndex: this.$store.state.currentSectionIndex,
            loadedIndex: false
        };
    },
    computed: {
        currentIndex() {
            return this.$store.state.currentSectionIndex;
        },
        sectionData() {
            return this.$store.state.sections;
        }
    },
    methods: {
        loadPage(page) {
            this.$http.get("/api/content?changepage=1&page=" + page).then(
                response => {
                    if (response.data.success === true) {
                        window.location.reload(false);
                    } else {
                        throw new Error("Could not retrieve content.");
                    }
                },
                response => {
                    throw new Error("Error retrieving content.");
                }
            );
        },
        isCurrentPage(page) {
            if (this.$store.state.currentPage == page) {
                return true;
            }
            return false;
        },
        setData(response) {
            let data = response.body ?? response.data ?? response;

            translator.install(data.pages);

            store.commit("setSections", data.sections);
            store.commit("setLastCompleted", data.last_completed);
            store.commit("setPageContent", data.pageContent);
            store.commit("setCurrentPage", data.currentPage);
            store.commit("setImages", data.images);

            store.commit("setShowTestimonialBar", false);

            for (const index in data.sections) {
                if (this.template === "customer-testimonials") {
                    this.$store.commit("setTestimonialSectionIndex", index);
                    this.$store.commit("setShowTestimonialBar", true);
                }

                if (data.sections[index].template === "modules") {
                    data.sections[index].modules.forEach(module => {
                        if (module.content?.splash?.source) {
                            const img = new Image();
                            img.src = module.content.splash.source;
                        }
                    });
                }
            }
            store.commit("setCurrentSectionIndex", 0);

            // AntiCookie initialization should occur once, as soon as we have detected that they are authenticated, and
            // after we have obtained page content.
            if (
                store.state.recentlyAuthenticated &&
                !store.state.user.is_impersonated
            ) {
                window.AntiCookie.init({
                    font: "'Avenir', Arial, Helvetica, sans-serif",
                    colors: {
                        primary: "#1767b3",
                        secondary: "#ecedeb"
                    },
                    persist: true,
                    toggles: ["tracking"],
                    cookieNoticeHandler: () => {
                        openCookieNotice();
                    },
                    translation: {
                        promptMessage: `This website uses cookies to improve your browsing experience. You can allow all cookies or customize your third-party cookie settings. Please see our {{cookieNoticeLink}} for more details.`
                    }
                });

                window.AntiCookie.shouldStorePreferences = true;
            }
        }
    },
    mixins: [ControlsMixin]
};
