import { createStore } from "vuex";
import { configure } from "vee-validate";
import { localize, setLocale } from "@vee-validate/i18n";

const store = createStore({
    state: {
        userLoadStatus: 0,
        recentlyAuthenticated: false,
        user: { id: null },
        sections: [],
        quizData: {},
        contentResponse: null,
        showRewardPage: false,
        currentRewardId: 0,
        lastCompleted: "",
        currentSectionIndex: 0,
        currentModuleIndex: null,
        currentQuestionIndex: null,
        testimonialSectionIndex: null,
        showTestimonialBar: false,
        showStatusBar: false,
        isTestimonialBarDismissed: false,
        currentTestimonial: "",
        pageContent: null,
        images: {
            main: "",
            modules: ""
        },
        currentPage: "Main",
        doResetModules: false,
        arrows: {
            left: {
                visibile: true,
                action: null
            },
            right: {
                visibile: true,
                action: null
            },
            goto: {
                afterAction: null
            }
        },
        crowdsourcingSections: [],
        currentCrowdsourcingId: null,
        showCrowdsourcingPage: false,
        translations: null,
        loading: false
    },
    mutations: {
        updateUser(state, userId) {
            state.user = userId;
        },
        completeSection(state, payload) {
            state.sections.forEach(function(section) {
                if (section.id === payload.id) {
                    section.completed_at = payload.completed_at;
                }
            });
        },
        completeModule(state, payload) {
            state.sections.forEach(function(section) {
                section.modules.forEach(function(module) {
                    if (module.id === payload.id) {
                        module.completed_at = payload.completed_at;
                    }
                });
            });
        },
        setUserHasAcceptedPrivacy(state, status) {
            state.user.has_accepted_privacy = status;
        },
        completeQuiz(state, id) {
            state.quizData[id].isComplete = true;
        },
        setShowRewardPage(state, value) {
            state.showRewardPage = value;
        },
        setCurrentRewardId(state, value) {
            state.currentRewardId = value;
        },
        setUserItem(state, value) {
            state.user.rewards[state.currentRewardId].item = value;
        },
        setContentResponse(state, value) {
            state.contentResponse = value;
        },
        setUserData(state, data) {
            state.user = data;
        },
        setUserPasswordUpdated(state) {
            state.user = {
                ...state.user,
                pw_expired: false
            };
        },
        setSections(state, sections) {
            state.sections = sections;
        },
        setUserLoadStatus(state, status) {
            state.userLoadStatus = status;
        },
        setRecentlyAuthenticated(state, value) {
            state.recentlyAuthenticated = value;
        },
        setQuizData(state, data) {
            state.quizData[data.id] = data;
            let sections = state.sections;
            let sKeys = Object.keys(sections);

            for (let s = 0; s < sKeys.length; s++) {
                let sKey = sKeys[s];
                let section = sections[sKey];

                let mKeys = Object.keys(section.modules);
                for (let m = 0; m < mKeys.length; m++) {
                    let mKey = mKeys[m];
                    let module = section.modules[mKey];

                    if (
                        typeof module.content.quiz_id !== "undefined" &&
                        module.content.quiz_id.translation == data.id
                    ) {
                        module.quizId = module.content.quiz_id.translation;
                        module.quiz = data;
                    }
                }
            }
        },
        setQuestionData(state, data) {
            let quizzes = state.quizData || false;

            if (quizzes === false) {
                return 0;
            }
            let quizKeys = Object.keys(quizzes);

            for (var i = 0; i < quizKeys.length; i++) {
                let currentQuizKey = quizKeys[i];

                let questions = quizzes[currentQuizKey].questions || false;
                let questionKeys = Object.keys(questions);

                for (var l = 0; l < questionKeys.length; l++) {
                    let currentQuestionKey = questionKeys[l];
                    if (questions[currentQuestionKey].id == data.id) {
                        state.quizData[currentQuizKey].questions[
                            currentQuestionKey
                        ] = data;
                    }
                }
            }
        },
        setLastCompleted(state, lastCompleted) {
            state.lastCompleted = lastCompleted;
        },
        updateModuleStatuses(state, moduleStatuses) {
            state.sections.forEach(function(section) {
                section.modules.forEach(function(module) {
                    if (moduleStatuses.hasOwnProperty(module.id)) {
                        module.locked = moduleStatuses[module.id] === "locked";
                    }
                });
            });
        },
        setCurrentSectionIndex(state, index) {
            state.currentSectionIndex = index;
        },
        setCurrentModuleIndex(state, index) {
            state.currentModuleIndex = index;
        },
        setCurrentQuestionIndex(state, index) {
            state.currentQuestionIndex = index;
        },
        setTestimonialSectionIndex(state, index) {
            state.testimonialSectionIndex = index;
        },
        setShowTestimonialBar(state, value) {
            if (!state.isTestimonialBarDismissed) {
                state.showTestimonialBar = value;
            }
        },
        setShowStatusBar(state, value) {
            state.showStatusBar = value;
        },
        dismissTestimonialBar(state) {
            state.isTestimonialBarDismissed = true;
        },
        setCurrentTestimonial(state, value) {
            state.currentTestimonial = value;
        },
        setPageContent(state, value) {
            state.pageContent = value;
        },
        setImages(state, value) {
            state.images = value;
        },
        setCurrentPage(state, value) {
            state.currentPage = value;
        },
        setResetModules(state, value) {
            state.doResetModules = value;
        },
        setRightArrowAction(state, value) {
            state.arrows.right.action = value;
        },
        setRightArrowVisibility(state, value) {
            state.arrows.right.visibile = value;
        },
        setLeftArrowAction(state, value) {
            state.arrows.left.action = value;
        },
        setLeftArrowVisibility(state, value) {
            state.arrows.left.visibile = value;
        },
        setAfterGotoAction(state, value) {
            state.arrows.goto.afterAction = value;
        },
        setCrowdsourcingSections(state, sections) {
            state.crowdsourcingSections = sections;
        },
        setShowCrowdsourcingPage(state, value) {
            state.showCrowdsourcingPage = value;
        },
        setCurrentCrowdsourcingId(state, value) {
            state.currentCrowdsourcingId = value;
        },
        setCrowdsourcingSectionComplete(state, sectionId) {
            state.crowdsourcingSections.forEach((section, sectionIndex) => {
                if (section.id === sectionId) {
                    state.crowdsourcingSections[
                        sectionIndex
                    ].completed_at = new Date();
                }
            });
        },
        setTranslations(state, value) {
            state.translations = value;
        },
        setLoading(state, value) {
            state.loading = value;
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        contentResponse(state) {
            return state.contentResponse;
        },
        userLoadStatus(state) {
            return state.userLoadStatus;
        },
        sections(state) {
            return state.sections;
        },
        currentPage(state) {
            return state.currentPage;
        },
        currentSectionIndex(state) {
            return state.currentSectionIndex;
        },
        pageContent(state) {
            return state.pageContent;
        },
        isGuest: state => {
            return state.user.id === null;
        },
        getBackground: state => {
            if (state.images) {
                return state.images.main;
            }
            return "";
        },
        getModuleBackground: state => {
            if (state.images.modules) {
                return state.images.modules;
            }
            return "";
        },
        /**
         * Returns the first module index that is not complete. If all modules complete, returns 0.
         * @param state
         * @returns {*}
         */
        activeModuleIndex: state => {
            let modulesSection = state.sections.find(
                section => section.template === "modules"
            );
            if (typeof modulesSection === "undefined") {
                return 0;
            }

            let activeModuleIndex = modulesSection.modules.findIndex(
                module => module.completed_at === null
            );
            if (activeModuleIndex === -1) {
                return 0;
            }

            return activeModuleIndex;
        },
        activeModuleSectionIndex: state => {
            var returnData = false;
            let sections = state.sections || false;

            if (sections === false) {
                return false;
            }
            let sKeys = Object.keys(sections);

            for (let s = 0; s < sKeys.length; s++) {
                let sKey = sKeys[s];
                let section = sections[sKey];
                if (section.completed_at === null) {
                    let mKey = null;
                    if (section.template === "modules") {
                        mKey = section.modules.findIndex(
                            module => module.completed_at === null
                        );
                    }
                    return { section: sKey, module: mKey };
                }
            }
            // couldn't find so return 0
            return false;
        },
        allSectionModules: state => {
            let returnData = [];
            let sections = state.sections || false;

            if (sections === false) {
                return returnData;
            }
            let sKeys = Object.keys(sections);

            for (let s = 0; s < sKeys.length; s++) {
                let sKey = sKeys[s];
                let section = sections[sKey];

                section.sectionIndex = sKey;
                section.type = "section";

                let mKeys = null;
                if (section.template === "modules") {
                    let mKeys = Object.keys(section.modules);

                    for (let m = 0; m < mKeys.length; m++) {
                        let mKey = mKeys[m];
                        let module = section.modules[mKey];

                        module.type = "module";
                        module.sectionIndex = sKey;
                        module.moduleIndex = mKey;
                        module.sectionId = section.id;
                        if (typeof module.content.quiz_id !== "undefined") {
                            module.quizId = module.content.quiz_id.translation;
                        }
                        returnData.push(module);
                    }
                } else {
                    returnData.push(section);
                }
            }
            return returnData;
        },
        crowdsourcingSections(state) {
            return state.crowdsourcingSections;
        },
        crowdsourcingSectionActive(state, getters) {
            return getters.crowdsourcingSections.find(
                section => section.id === state.currentCrowdsourcingId
            );
        },
        crowdsourcingSectionsIncomplete(state, getters) {
            return getters.crowdsourcingSections.filter(
                section => section.completed_at === null
            );
        },
        translationsLoaded(state) {
            return state.translations !== null;
        },
        translations(state) {
            return state.translations;
        },
        isLoading(state) {
            return state.loading;
        }
    },
    actions: {
        async fetchTranslations({ commit, getters }) {
            const {
                data: { content }
            } = await window.axios.get("/api/pages/translations");
            const { language = "en-us" } = getters.user;

            if (content.validation_messages) {
                configure({
                    generateMessage: localize({
                        [language]: {
                            messages: content.validation_messages
                        }
                    })
                });
                setLocale(language);
            }

            commit("setTranslations", content);
        }
    }
});

export default store;
