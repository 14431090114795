<style lang="scss" rel="stylesheet/scss">
#testimonial-footer {
    z-index: 99;

    .heading,
    .button {
        flex-shrink: 0;
    }

    .description {
        max-width: 800px;
    }
}
</style>

<template>
    <div id="testimonial-footer" v-if="isVisible">
        <div @click="close" class="x-button close-testimonial-footer"></div>
        <div class="heading" v-html="this.t('Anchor', 'Left', 0)"></div>
        <div class="description"><p v-html="currentTestimonial"></p></div>
        <a
            href="#"
            class="button"
            @click.prevent="gotoTestimonials()"
            v-html="this.t('Anchor', 'Button', 0)"
        ></a>
    </div>
</template>

<script type="text/babel">
import TranslationMixin from "./mixins/TranslationMixin";
export default {
    name: "testimonial-footer",
    props: ["isVisible", "section"],
    data() {
        return {};
    },
    methods: {
        close() {
            this.$store.commit("setShowTestimonialBar", false);
            this.$store.commit("dismissTestimonialBar");
        },
        gotoTestimonials() {
            this.$store.commit(
                "setCurrentSectionIndex",
                this.$store.state.testimonialSectionIndex + 1
            );
        }
    },
    computed: {
        currentTestimonial() {
            let testimonial = this.$store.state.currentTestimonial;
            let truncatedTestimonial = this.$store.state.currentTestimonial.substring(
                0,
                280
            );
            if (testimonial.length !== truncatedTestimonial.length) {
                truncatedTestimonial += "...";
            }
            return truncatedTestimonial.replace(/(<br\ ?\/?>)+/, "");
        }
    },
    mixins: [TranslationMixin]
};
</script>
