<style lang="scss" rel="stylesheet/scss">
.module.three-sections {
    .icon {
        margin-bottom: 10px;
    }

    h1 {
        text-align: left;
    }

    .buttons {
        justify-content: flex-start;
    }

    .columns {
        display: flex;
        width: 100%;

        .column {
            width: 50%;
            margin-right: 60px;

            &.left {
                p {
                    margin-bottom: 20px;
                }
            }
        }

        .column:last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 971px) {
        .carousel-column {
            transform: scale(0.7);
        }
    }

    @media (max-width: 767px) {
        .carousel-column {
            transform: scale(1);
        }

        .icon {
            margin-bottom: 5px;
            > img {
                transform: scale(0.5);
            }
        }

        .columns {
            display: flex;

            .column {
                margin-right: 0;
                width: 100%;
            }

            .column:last-child {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        .columns {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            .column {
                width: 100%;
            }
        }

        .buttons {
            justify-content: center;
        }

        .column.carousel-column {
        }
    }
}
</style>

<template>
    <div class="module three-sections">
        <div class="columns">
            <div class="column left">
                <h1>
                    <content-item :config="content.header_1"></content-item>
                </h1>
                <p><content-item :config="content.content_1"></content-item></p>

                <h1>
                    <content-item :config="content.header_2"></content-item>
                </h1>
                <p><content-item :config="content.content_2"></content-item></p>

                <h1>
                    <content-item :config="content.header_3"></content-item>
                </h1>
                <p><content-item :config="content.content_3"></content-item></p>
            </div>
            <div class="column carousel-column">
                <content-carousel
                    :config="content.carousel"
                    :activeItemIndex="activeItemIndex"
                    @indexUpdate="setActiveItemIndex($event)"
                ></content-carousel>
            </div>
        </div>

        <div class="buttons">
            <step-button @click="previous()" direction="left" v-if="hasPrevious"
                >Previous step</step-button
            >
            <step-button
                @click="next()"
                v-if="hasNext || content.carousel.items.length > 1"
                >Next step</step-button
            >
        </div>
    </div>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
import ActiveItemsMixin from "../ActiveItemsMixin";
import ContentCarousel from "../../content/ContentCarousel.vue";

export default {
    name: "module-three-sections",
    data() {
        return {};
    },
    mixins: [ModuleMixin, ActiveItemsMixin],
    components: {
        ContentCarousel
    }
};
</script>
