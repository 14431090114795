<template>
    <div v-if="!isDismissed" class="privacy-popup">
        <div class="privacy-popup--content">
            <div>
                {{ message }}
            </div>
            <div class="privacy-popup--links">
                <a
                    href="#"
                    @click.prevent="showPolicyModal('PrivacyNotice')"
                    target="_blank"
                    >{{ privacyLinkText }}</a
                >
                |
                <a
                    href="#"
                    @click.prevent="showPolicyModal('CookieNotice')"
                    target="_blank"
                    >{{ cookieLinkText }}</a
                >
            </div>
            <div class="privacy-popup--button-container">
                <button @click.prevent="dismiss" class="button">
                    {{ buttonText }}
                </button>
            </div>
        </div>
        <modal
            v-model="shouldShowPolicyModal"
            v-if="shouldShowPolicyModal"
            theme="vex-middle-close vex-full-screen"
        >
            <policy-modal-page :pageName="currentPolicyModalName" />
        </modal>
    </div>
</template>

<script>
import Modal from "./Modal.vue";
import PolicyModalPage from "./pages/PolicyModalPage.vue";

export default {
    name: "privacy-popup",
    components: {
        Modal,
        PolicyModalPage
    },
    props: {
        message: String,
        privacyLinkText: String,
        cookieLinkText: String,
        buttonText: String
    },
    data() {
        return {
            isDismissed: false,
            userData: this.$store.state.user,
            shouldShowPolicyModal: false,
            currentPolicyModalName: ""
        };
    },
    computed: {
        isUserLoggedIn() {
            return this.$store.getters.user.id !== null;
        }
    },
    methods: {
        showPolicyModal(pageName) {
            this.currentPolicyModalName = pageName;
            this.shouldShowPolicyModal = true;
        },
        acceptTerms() {
            if (this.isUserLoggedIn) {
                this.$store.commit("setUserHasAcceptedPrivacy", true);
                return this.$http.post("/api/privacy/accept");
            } else {
                // If we're not logged in, accepting the
                // cookie notice is effectively a no-op.
                return Promise.resolve();
            }
        },
        dismiss() {
            this.acceptTerms().then(() => {
                this.isDismissed = true;

                this.$emit("dismiss");
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/scss/breakpoints";

.privacy-popup {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.9);
    left: 0;
    position: fixed;
    top: 0;
    z-index: 1000;

    .privacy-popup--content {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        text-align: center;
        color: white;
        padding: 1.5rem;

        @media (min-width: map-get($grid-breakpoints, "sm")) {
            width: 500px;
            padding: 0;
        }

        .privacy-popup--button-container {
            margin-top: 20px;
        }

        button {
            padding: 20px 105px;
            font-size: 18px;
            font-weight: bold;
            border: none;
            cursor: pointer;
            margin-top: 20px;
            transition: all 0.2s;
            display: inline-block;
        }
    }
}
</style>
