<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color.scss";

.section {
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
}

.section-wrapper {
    width: 100%;
}

.section-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
</style>

<template>
    <div
        class="section pp-scrollable"
        :style="{ backgroundImage: 'url(' + buildBackgroundImageURL() + ')' }"
    >
        <div class="section-wrapper">
            <div class="section-data">
                <slot></slot>
            </div>

            <div v-if="showTestimonialSpacer" class="testimonial-spacer"></div>
        </div>
    </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import eventBus from "@/event-bus";

export default {
    name: "section-wrapper",
    props: ["hasBackground"],
    data() {
        return {};
    },
    created() {
        // Use an event bus to reproduce legacy communication between components
        eventBus.$on("section-change", () => {
            this.$el.scrollTop = 0;
        });
    },
    unmounted() {
        eventBus.$off("section-change");
    },
    computed: {
        ...mapGetters(["getBackground"]),
        showTestimonialSpacer() {
            return this.$store.state.showTestimonialBar;
        }
    },
    methods: {
        buildBackgroundImageURL() {
            return this.hasBackground !== false ? this.getBackground : "";
        }
    }
};
</script>
