<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";

.quiz-finish {
    margin-bottom: 40px;
    max-width: 80%;
    text-align: center;

    h1 {
        font-size: 54px;
        font-weight: bolder;
    }
    .grade {
        color: yellow;
    }
    .body-message {
        max-width: 420px;
        margin: 20px auto;
    }
    button.button {
        margin: auto;
    }
}
</style>

<template>
    <div class="quiz-finish">
        <div class="body-message" v-html="messageHtml"></div>
        <button class="button" @click="nextSection">{{ button }}</button>
    </div>
</template>

<script type="text/babel">
import ControlsMixin from "../mixins/ControlsMixin";

export default {
    name: "quiz-finish",
    props: ["questions", "correct", "message", "button"],
    data() {
        return {};
    },
    created() {
        this.showRightArrow();
    },
    computed: {
        messageHtml() {
            return this.message
                .replace("{{correct}}", this.correct)
                .replace("{{questions}}", this.questions);
        }
    },
    methods: {
        nextSection() {
            this.up();
        }
    },
    mixins: [ControlsMixin]
};
</script>
