<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.module-landing-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    height: 100%;
    min-width: 100vw;
    align-items: flex-start;

    padding: 0px;
    font-size: 16px;
    margin-top: 0px;

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100%;
        padding-top: 0;
        padding-bottom: 0;
    }

    h1 {
        margin-bottom: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
    }

    .blur-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2;
        // filter: blur(10px);
        background-color: black;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 0;
    }

    .imagebutton {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        width: 100%;
        padding-top: 30px;
        flex-wrap: wrap;

        img {
            max-width: 200px;
        }
    }

    @media (max-width: 767px) {
        h1 {
            font-size: 60px;
        }
    }

    .module-landing-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
</style>

<template>
    <div
        class="module-landing-page"
        :style="{ 'background-image': 'url(' + getBackgroundImage + ')' }"
    >
        <div class="content-container">
            <div class="module-landing-content">
                <h1>
                    <content-item :config="content.title"></content-item>
                </h1>

                <p>
                    <content-item :config="content.subheader"></content-item>
                </p>

                <div class="imagebutton">
                    <content-item
                        v-if="content.product_image1"
                        :config="content.product_image1"
                    ></content-item>

                    <button class="button" v-on:click="gotoModule()">
                        <content-item
                            class="circle inner"
                            :config="content.button"
                        ></content-item>
                    </button>

                    <content-item
                        v-if="content.product_image2"
                        :config="content.product_image2"
                    ></content-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
import ControlsMixin from "../../mixins/ControlsMixin";
export default {
    name: "module-landing-page",
    data() {
        return {};
    },
    computed: {
        getBackgroundImage() {
            return this.$store.getters.getModuleBackground;
        },
        parentSectionIndex() {
            return this.$store.state.currentSectionIndex;
        }
    },
    mounted() {
        this.complete();
    },
    methods: {
        gotoModule() {
            this.down();
        }
    },
    mixins: [SectionMixin, ControlsMixin]
};
</script>
