export default {
    methods: {
        replaceData(item, additionalReplacements) {
            for (var key in additionalReplacements) {
                var replace = "{{" + key + "}}";
                var re = new RegExp(replace, "g");
                var replacement = additionalReplacements[key];
                if (replacement === null) {
                    replacement = "";
                }
                item.translation = item.translation.replace(re, replacement);
            }
            for (var key in userData.user) {
                var replace = "{{userData.user." + key + "}}";
                var re = new RegExp(replace, "g");
                item.translation = item.translation.replace(
                    re,
                    encodeURIComponent(userData.user[key])
                );
            }
            for (var key in contentData.content) {
                var replace = "{{contentData.content." + key + "}}";
                var re = new RegExp(replace, "g");
                item.translation = item.translation.replace(
                    re,
                    encodeURIComponent(contentData.content[key])
                );
            }
            return item;
        }
    }
};
