<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";
@import "@/scss/font";

.lock-overlay {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($background-grey, 0.5);
    z-index: 10;

    &.show {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pill {
        width: 100%;
        max-width: 644px;
        height: 233px;
        border-radius: 200px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        box-sizing: border-box;

        .text {
            @extend .lock-text;
            padding-left: 40px;
        }

        .lock {
            flex-shrink: 0;
            border-radius: 50%;
            width: 203px;
            height: 203px;
            background-color: $primary-color;
            display: flex;
            align-items: center;
            justify-content: center;

            .lock-image {
                background-image: url("../../assets/images/lock.png");
                width: 87px;
                height: 117px;
                background-size: cover;
            }
        }
    }
    @media (max-width: 767px) {
        .pill {
            width: 100%;
            max-width: 322px;
            height: 116.5px;
            border-radius: 100px;
            padding-left: 10px;

            .text {
                padding-left: 20px;
            }

            .lock {
                width: 101.5px;
                height: 101.5px;

                .lock-image {
                    width: 43.5px;
                    height: 58.5px;
                }
            }
        }
    }
}
</style>

<template>
    <div :class="['lock-overlay', { show: show }]">
        <div class="pill">
            <div class="lock"><div class="lock-image"></div></div>
            <div class="text">{{ text }}</div>
        </div>
    </div>
</template>

<script type="text/babel">
import translator from "../../api/translator";

export default {
    name: "lock-overlay",
    props: ["show"],
    data() {
        return {
            text: translator.t(
                this.$store.state.currentPage,
                "Modules",
                "LockScreen",
                0
            )
        };
    }
};
</script>
