import translator from "../../api/translator";
export default {
    methods: {
        t(sectionName, locationName, position) {
            let result = translator.t(
                this.$store.state.currentPage,
                sectionName,
                locationName,
                position
            );
            if (result === false) {
                throw new Error(
                    `Could not find translation Main ${sectionName} ${locationName} ${position}`
                );
            }
            return result;
        }
    }
};
