/*
    This mixin is weird. There were references to userType but nothing setting it so I added a default value.
    The computed property is setting data? That is not good.
 */
export default {
    data() {
        return {
            footerLogoImageData: {},
            userType: "default"
        };
    },
    computed: {
        setFooterLogoImage() {
            if (
                typeof this.footerLogoImageData[this.userType] !== "undefined"
            ) {
                return this.footerLogoImageData[this.userType];
            }
            this.footerLogoImageData[this.userType] = { JSON };
            this.footerLogoImageData[this.userType] = {
                userType: "auto-zone",
                footerLogoImageURL: window.userData.user.logo || false
            };
            return this.footerLogoImageData[this.userType];
        }
    }
};
