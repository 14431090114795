<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.program-landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-size: 16px;

    h1 {
        margin-bottom: 20px;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
        margin: auto;
    }

    .imagebutton {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        width: 100%;
        padding-top: 30px;
        flex-wrap: wrap;

        img {
            max-width: 200px;
        }
    }
    .circle.inner {
        background-color: #ccaf5f;
        //border: 2px solid white;
        width: 224px;
        height: 69px;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        color: #000;
        text-transform: uppercase;
    }
    .circle.outter {
        margin: 20px;
        cursor: pointer;
        border: none;
        padding: 0;
    }

    @media (max-width: 767px) {
        h1 {
            font-size: 60px;
        }
    }
}
</style>

<template>
    <div class="content-container">
        <div class="program-landing-page">
            <div>
                <h1>
                    <content-item :config="content.title"></content-item>
                </h1>

                <p>
                    <content-item :config="content.subheader"></content-item>
                </p>

                <div class="imagebutton">
                    <content-item
                        v-if="content.product_image1"
                        :config="content.product_image1"
                    ></content-item>

                    <button
                        class="button"
                        v-if="showStatusbar"
                        v-on:click="gotoModule()"
                    >
                        <content-item :config="content.button"></content-item>
                    </button>

                    <content-item
                        v-if="content.product_image2"
                        :config="content.product_image2"
                    ></content-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
import ControlsMixin from "../../mixins/ControlsMixin";
import IconFooter from "../../IconFooter.vue";
export default {
    components: { IconFooter },
    name: "program-landing-page",
    data() {
        return {};
    },
    created() {
        //console.log(this, "Check::::")
    },
    computed: {
        parentSectionIndex() {
            return this.$store.state.currentSectionIndex;
        },
        showStatusbar() {
            return this.$store.state.showStatusBar;
        },
        sectionModules() {
            return this.getAllSectionModules();
        }
    },
    mounted() {
        this.complete();
    },
    methods: {
        gotoModule() {
            this.$forceUpdate();
            let sections = this.sectionModules;
            let sKeys = Object.keys(sections);
            let lastCompletedAt = null;
            let lastCompleted = null;

            for (let s = 0; s < sKeys.length; s++) {
                let sKey = sKeys[s];
                let section = sections[sKey];

                if (
                    section.type === "section" &&
                    section.completed_at === null
                ) {
                    this.gotoSectionModule(parseInt(section.sectionIndex));
                    return;
                } else if (
                    section.type === "module" &&
                    (this.$store.state.quizData[section.quizId].isComplete ==
                        false ||
                        section.completed_at === null)
                ) {
                    // This previously navigated to the section before the first incomplete module. This worked fine
                    // when there was always a module-landing-page section before a modules section. The requirement
                    // provided is "User should see the quiz with the lowest order that user has not completed" so let's
                    // just go to first incomplete module.
                    this.gotoSectionModule(
                        parseInt(section.sectionIndex),
                        parseInt(section.moduleIndex)
                    );
                    return;
                } else if (section.completed_at !== null) {
                    let thisDate = new Date(section.completed_at);
                    if (
                        lastCompletedAt === null ||
                        thisDate >= lastCompletedAt
                    ) {
                        lastCompletedAt = thisDate;
                        lastCompleted = section;
                    }
                }
            }

            if (lastCompleted !== null) {
                if (
                    "sectionIndex" in lastCompleted &&
                    "moduleIndex" in lastCompleted
                ) {
                    this.gotoSectionModule(
                        parseInt(lastCompleted.sectionIndex),
                        parseInt(lastCompleted.moduleIndex)
                    );
                } else if ("sectionIndex" in lastCompleted) {
                    this.gotoSectionModule(
                        parseInt(lastCompleted.sectionIndex)
                    );
                }
            }
        }
    },
    mixins: [SectionMixin, ControlsMixin]
};
</script>
