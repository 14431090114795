<template>
    <div :class="['question', { last: last }]">
        <label :for="name" v-html="t('text')" />
        <textarea
            :id="name"
            :name="name"
            rows="4"
            placeholder="Type Comment..."
            @input="$emit('update:modelValue', $event.target.value)"
            :aria-required="required ? 'true' : 'false'"
            :aria-invalid="invalid ? 'true' : 'false'"
        />
        <slot name="error"></slot>
    </div>
</template>

<script>
export default {
    name: "CrowdsourcingQuestion",
    emits: ["update:modelValue"],
    props: {
        name: {
            type: String,
            required: true
        },
        content: {
            type: Object,
            required: true
        },
        last: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        invalid: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        t(name) {
            if (
                this.content !== "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/color";

.question {
    margin-bottom: 1rem;

    &.last {
        margin-bottom: 1.5rem;
    }
}
label {
    display: block;
    margin-bottom: 1rem;
}
textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.2;
    border: 1px solid $grey;
    box-sizing: border-box;
}
</style>
