<style lang="scss" rel="stylesheet/scss">
.module.activity-link {
    max-width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    flex-direction: row;
    align-items: stretch;
    padding: 0;

    .content-container {
        align-items: center;

        // Need extra spacing to not run into absolutely positioned nav summary
        padding-top: 80px;
    }

    a {
        text-decoration: none !important;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
        font-size: 16px;
    }
    .center-item {
        width: 190px;
        height: 190px;
        position: fixed;
    }

    .image-splash {
        max-width: 100%;
        max-height: 100%;
    }

    .image-button-container {
        padding-top: 25px;
        padding-bottom: 21px;
        max-width: 508px;
        width: 100%;
        margin: 20px !important;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>

<template>
    <div
        class="module activity-link"
        :style="{ 'background-image': 'url(' + getModuleBackgroundImage + ')' }"
        v-if="showActivity"
    >
        <div class="content-container">
            <h1>
                <content-item :config="content.title"></content-item>
            </h1>

            <p>
                <content-item :config="content.subheader"></content-item>
            </p>

            <div class="image-button-container">
                <a
                    :href="linkUrl"
                    @click="start"
                    target="resourceWindow"
                    rel="noopener noreferrer"
                >
                    <content-item
                        v-if="content.image"
                        class="image-splash"
                        :config="content.image"
                    ></content-item>
                    <content-item
                        v-else-if="content.resource_name"
                        :config="content.resource_name"
                    ></content-item>
                    <span v-else>View Resource</span>
                </a>
            </div>

            <a
                v-if="!started"
                :href="linkUrl"
                target="resourceWindow"
                rel="noopener noreferrer"
            >
                <button @click="start" class="button">
                    <content-item :config="content.button"></content-item>
                </button>
            </a>
            <button v-else class="button" v-on:click="showQuiz">
                <content-item
                    v-if="quizComplete"
                    :config="content.buttonquizcomplete"
                ></content-item>
                <content-item
                    v-else
                    :config="content.buttoncomplete"
                ></content-item>
            </button>
        </div>
    </div>
    <quiz-component
        class="module activity-link"
        :style="{ 'background-image': 'url(' + getModuleBackgroundImage + ')' }"
        v-else
        :quizData="quizData"
        :quizId="quizId"
    ></quiz-component>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
import QuizMixin from "../../mixins/QuizMixin";
import QuizComponent from "../../quiz/QuizComponent.vue";

export default {
    components: { QuizComponent },
    name: "activity-template-link",
    data() {
        return {
            showActivity: true,
            quizId: this.content.quiz_id.translation,
            background: this.content.splash.source,
            linkViewed: false
        };
    },
    methods: {
        start() {
            this.linkViewed = true;
        },
        showActivities() {
            this.endQuizNavigation();
            this.showActivity = true;
        },
        showQuiz() {
            this.startQuizNavigation(this.showActivities);
            this.showActivity = false;
        }
    },
    computed: {
        started() {
            return this.quizComplete || this.linkViewed;
        },
        linkUrl() {
            const value = this.content.resource_url.translation;
            return new RegExp("^http(s?)://").test(value)
                ? value
                : `http://${value}`;
        },
        getModuleBackgroundImage() {
            return this.background;
        }
    },
    mixins: [ModuleMixin, QuizMixin]
};
</script>
