<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.modules {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    min-width: 100vw;

    .module-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .module.locked {
        filter: blur(2.2px);
    }
}
</style>

<template>
    <div class="modules">
        <div class="module-wrapper">
            <lock-overlay
                :show="
                    typeof currentModule !== 'undefined' && currentModule.locked
                "
            ></lock-overlay>
            <component
                v-show="isActiveIndex(key)"
                @previous-section="previous()"
                @next-section="next()"
                :is="filterTemplate(module.template)"
                :template="module.template"
                v-for="(module, key) in modules"
                :content="module.content"
                :completedAt="module.completed_at"
                :key="key"
                :hasPrevious="key > 0"
                :hasNext="key < modules.length - 1"
                :class="{ locked: module.locked }"
                :isActive="isActiveIndex(key)"
                :lock_section_modules="module.lock_section_modules"
                :module="module"
                :doPerformReset="resetModuleFlags[key]"
                @reset="clearResetModule(key)"
            ></component>
        </div>
    </div>
</template>

<script type="text/babel">
import SectionMixin from "../SectionMixin";
import ModuleBlank from "../../modules/templates/ModuleBlank.vue";
import ModuleCopyCarousel from "../../modules/templates/ModuleCopyCarousel.vue";
import ModuleCopyVideo from "../../modules/templates/ModuleCopyVideo.vue";
import ModuleFinal from "../../modules/templates/ModuleFinal.vue";
import ModuleHeaderTwoImages from "../../modules/templates/ModuleHeaderTwoImages.vue";
import ModuleTabbedContent from "../../modules/templates/ModuleTabbedContent.vue";
import ModuleThreeBoxes from "../../modules/templates/ModuleThreeBoxes.vue";
import ModuleThreeSections from "../../modules/templates/ModuleThreeSections.vue";
import LockOverlay from "../../modules/LockOverlay.vue";
import ModuleSummary from "../../modules/ModuleSummary.vue";

import ActivityTemplateVideo from "../../modules/templates/ActivityTemplateVideo.vue";
import ActivityTemplatePdf from "../../modules/templates/ActivityTemplatePdf.vue";
import ActivityTemplateLink from "../../modules/templates/ActivityTemplateLink.vue";

export default {
    name: "modules",
    data() {
        let resetModuleFlags = {};
        for (const index in this.modules) {
            resetModuleFlags[index] = false;
        }

        let activeModuleIndex = this.$store.getters.activeModuleIndex;
        //this.$store.commit('setCurrentModuleIndex', activeModuleIndex);

        return {
            resetModuleFlags: resetModuleFlags
        };
    },
    methods: {
        clearResetModule(index) {
            this.resetModuleFlags[index] = false;
        },
        resetModules() {
            let vm = this;
            for (const index in this.modules) {
                vm.resetModuleFlags[index] = true;
            }
        },
        filterTemplate(template) {
            if (template === "module-final-accept-button") {
                return "module-final";
            }
            return template;
        },
        next() {
            this.incrementSection();
        },
        previous() {
            if (this.currentModuleIndex > 0) {
                this.setActiveIndex(this.currentModuleIndex - 1);
            }
        },
        incrementSection() {
            if (this.currentModuleIndex >= this.modules.length - 1) {
                return;
            }
            this.setActiveIndex(this.currentModuleIndex + 1);
        },
        isActiveIndex(index) {
            return index === this.currentModuleIndex;
        },
        setActiveIndex(index) {
            this.$store.commit("setCurrentModuleIndex", index);
        }
    },
    computed: {
        getBackgroundImage() {
            return this.$store.getters.getModuleBackground;
        },
        currentModule() {
            return this.modules[this.currentModuleIndex];
        },
        currentModuleIndex() {
            let currentIndex = this.$store.state.currentModuleIndex;
            if (typeof currentIndex !== "number") {
                return 0;
            }
            return currentIndex;
        },
        doResetModules() {
            return this.$store.state.doResetModules;
        }
    },
    watch: {
        doResetModules(newValue) {
            if (newValue) {
                this.resetModules();
                this.$store.commit("setResetModules", false);
            }
        }
    },
    mixins: [SectionMixin],
    components: {
        ModuleBlank,
        ModuleCopyCarousel,
        ModuleCopyVideo,
        ModuleFinal,
        ModuleHeaderTwoImages,
        ModuleTabbedContent,
        ModuleThreeBoxes,
        ModuleThreeSections,
        LockOverlay,
        ModuleSummary,
        ActivityTemplateVideo,
        ActivityTemplatePdf,
        ActivityTemplateLink
    }
};
</script>
