<template>
    <div class="spinner-overlay">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 50 50"
            style="enable-background:new 0 0 50 50;"
            xml:space="preserve"
        >
            <path
                class="spinner-overlay__icon"
                d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
    </div>
</template>

<script>
export default {
    name: "SpinnerOverlay"
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";

.spinner-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: 0 0 60px 0 #000000 inset;
    position: absolute;
    top: 0;
    z-index: 10000;

    &__icon {
        fill: $primary-color;
        stroke: $grey;
    }
}
</style>
