import { inject } from "vue";
import axios from "axios";
import store from "@/store";

const isContentRequest = url => url.startsWith("/api/content");

const injectionKey = Symbol("http");

export const useHttp = () => inject(injectionKey);

export const plugin = {
    install(app) {
        const http = axios.create();

        // Set csrf header to token
        http.defaults.headers.common[
            "X-CSRF-TOKEN"
        ] = document.head.querySelector('meta[name="csrf-token"]').content;

        // Request interceptor to automatically display loading indicator.
        http.interceptors.request.use(request => {
            if (isContentRequest(request.url)) {
                store.commit("setLoading", true);
            }

            return request;
        });

        // Response interceptor to automatically hide loading indicator and potentially redirect.
        http.interceptors.response.use(response => {
            if (isContentRequest(response.config.url)) {
                store.commit("setLoading", false);
            }

            if (typeof response.data.redirect !== "undefined") {
                store.commit("updateUser", { id: null });
                window.location = response.data.redirect;
            }

            return response;
        });

        app.provide(injectionKey, http);
        app.config.globalProperties.$http = http;
        //
        window.axios = http;
    }
};
