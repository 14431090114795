<template>
    <button
        v-bind="$attrs"
        :disabled="modelValue"
        @click="$emit('click')"
        class="button"
    >
        <svg
            class="loading-indicator"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="20px"
            height="20px"
            viewBox="0 0 50 50"
            style="enable-background:new 0 0 50 50;"
            xml:space="preserve"
            v-show="modelValue"
        >
            <path
                fill="#000"
                d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "LoadingButton",
    props: {
        modelValue: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
button {
    .loading-indicator {
        margin-right: 0.5rem;
    }
}
</style>
