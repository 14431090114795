import ControlsMixin from "./ControlsMixin";
import eventBus from "@/event-bus";

export default {
    created() {
        this.$store.commit("setShowStatusBar", false);
        var vm = this;
        this.$http.get("/api/quiz/index/id/" + this.quizId).then(
            response => {
                if (response.data.success === true) {
                    this.$store.commit("setQuizData", response.data);
                    this.$store.commit("setShowStatusBar", true);
                    vm.quizComplete = response.data.isComplete;
                } else {
                    //this.quizData.error = "Could not retrieve content."
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    data() {
        return {
            quizComplete: false
        };
    },
    computed: {
        quizData() {
            let quizId = parseInt(this.quizId);
            let data = this.$store.state.quizData;
            if (typeof data[quizId] !== "undefined") return data[quizId];
            return {};
        },
        isQuizComplete() {
            return (
                typeof this.quizData.isComplete !== "undefined" &&
                this.quizData.isComplete
            );
        }
    },
    methods: {
        endQuizNavigation() {
            eventBus.$emit("section-change");
            this.showRightArrow();
            this.showLeftArrow();
            this.setRightArrowAction(null);
            this.setLeftArrowAction(null);
        },
        startQuizNavigation(action) {
            eventBus.$emit("section-change");
            this.$store.commit(
                "setCurrentQuestionIndex",
                this.getQuizIndexById(this.quizData.nextquestion)
            );
            this.hideRightArrow();
            this.setLeftArrowAction(action);
            this.setOnceAfterGotoSectionModule(action);
        },
        getQuizIndexById(id) {
            let items = this.quizData.questions || false;

            if (items === false) {
                return 0;
            }
            let keys = Object.keys(items);

            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (items[key].id == id) {
                    return key;
                }
            }
            // couldn't find so return 0
            return 0;
        }
    },
    mixins: [ControlsMixin]
};
