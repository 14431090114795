<template>
    <div class="section-container" v-if="showResourceSection">
        <h2 class="header-content">
            <content-text
                v-if="content"
                :config="content.header"
            ></content-text>
        </h2>
        <p>
            <content-text v-if="content" :config="content.body"></content-text>
        </p>
        <div class="header-section">
            <component
                v-for="(module, index) in modules"
                :is="module.template"
                :key="module.id"
                :resource="module.content"
            ></component>
        </div>
    </div>
</template>

<script type="text/babel">
import ContentText from "../../content/ContentText.vue";
import ResourceLink from "../../modules/templates/ResourceLink.vue";
export default {
    props: ["content", "modules"],
    components: {
        ResourceLink,
        ContentText
    },
    name: "resources-section",
    computed: {
        showResourceSection() {
            let visibleSections = [
                "VIDEOS",
                "MOVIES",
                "SERIES & DOCS",
                "SERIES AND DOCS",
                "SERIES &AMP; DOCS"
            ];
            return this.$store.getters.user.id
                ? true
                : visibleSections.includes(
                      this.content.header.translation.toUpperCase()
                  );
        }
    }
};
</script>

<style lang="scss" rel="scss/stylesheet">
.section-container {
    border-bottom: 1px solid #000;
    padding: 20px;
    .header-content {
        font-size: 30px;
    }
    &:last-child {
        border-bottom: none;
    }
}

.header-section {
    display: block;
}

@media (max-width: 767px) {
    .section-container .header-content {
        font-size: 24px;
    }
}
</style>
