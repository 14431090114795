<style lang="scss" rel="stylesheet/scss">
.two-images {
    .images {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 767px) {
        .images {
            flex-wrap: wrap;
            img {
                height: auto;
                width: 100%;
            }
        }
    }
}
</style>

<template>
    <div class="module two-images">
        <h1>
            <content-item :config="content.header"></content-item>
        </h1>
        <p>
            <content-item :config="content.content"></content-item>
        </p>

        <div class="images">
            <content-item :config="content.image_1"></content-item>
            <content-item :config="content.image_2"></content-item>
        </div>

        <div class="buttons">
            <step-button
                @click="previousSection()"
                direction="left"
                v-if="hasPrevious"
            ></step-button>
            <step-button @click="nextSection()" v-if="hasNext"></step-button>
        </div>
    </div>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
export default {
    name: "module-header-two-images",
    data() {
        return {};
    },
    mixins: [ModuleMixin]
};
</script>
