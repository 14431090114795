<style lang="scss" rel="stylesheet/scss">
.module.copy-carousel {
    .icon {
        margin-bottom: 10px;
    }

    h1 {
        text-align: left;
    }

    .buttons {
        justify-content: flex-start;
    }

    .columns {
        display: flex;
        width: 100%;

        .column {
            margin-right: 20px;
        }

        .column.first {
            width: 60%;
        }

        .column.second {
            width: 40%;
        }

        .column:last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 971px) {
        .carousel-column {
            transform: scale(0.75);
        }
    }

    @media (max-width: 767px) {
        .carousel-column {
            transform: scale(1);
        }

        .icon {
            margin-bottom: 5px;
            > img {
                transform: scale(0.5);
            }
        }

        .columns {
            display: flex;

            .column.first,
            .column.second {
                margin-right: 0;
                width: 100%;
            }

            .column:last-child {
                margin-right: 0;
            }
        }

        .columns {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            .column {
                width: 100%;
            }
        }

        .buttons {
            justify-content: center;
        }

        .column.carousel-column {
        }
    }
}
</style>

<template>
    <div class="module copy-carousel">
        <div class="columns">
            <div class="column first">
                <div class="icon">
                    <content-item :config="content.icon"></content-item>
                </div>
                <h1><content-item :config="content.header"></content-item></h1>
                <p><content-item :config="content.content"></content-item></p>

                <div class="buttons">
                    <step-button
                        @click="previous()"
                        direction="left"
                        v-if="hasPrevious"
                    ></step-button>
                    <step-button
                        @click="next()"
                        v-if="hasNext || content.carousel.items.length > 1"
                    ></step-button>
                </div>
            </div>
            <div class="column second carousel-column">
                <content-carousel
                    :config="content.carousel"
                    :activeItemIndex="activeItemIndex"
                    @indexUpdate="setActiveItemIndex($event)"
                ></content-carousel>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
import ActiveItemsMixin from "../ActiveItemsMixin";
import ContentCarousel from "../../content/ContentCarousel.vue";
export default {
    name: "module-copy-carousel",
    data() {
        return {
            items: this.content.carousel.items
        };
    },
    mixins: [ModuleMixin, ActiveItemsMixin],
    components: {
        ContentCarousel
    }
};
</script>
