<style lang="scss" rel="stylesheet/scss"></style>

<template>
    <span v-html="config.translation"></span>
</template>

<script type="text/babel">
export default {
    name: "content-text",
    props: ["config"]
};
</script>
